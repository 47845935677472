import * as React from 'react';

import { cn } from '../../_utils/cn';

import InputWrapper from './InputWrapper';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  helperText?: string | React.ReactNode;
  inputClassName?: string;
  labelInfo?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, inputClassName, labelText, helperText, type, labelInfo, ...props }, ref) => {
    return (
      <InputWrapper labelText={labelText} helperText={helperText} className={className} labelInfo={labelInfo}>
        <input
          type={type}
          className={cn(
            'shadow-sm flex h-10 w-full rounded-lg border border-wb-primary bg-wb-primary px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-wb-tertiary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            inputClassName
          )}
          ref={ref}
          {...props}
        />
      </InputWrapper>
    );
  }
);
Input.displayName = 'Input';

export { Input };
