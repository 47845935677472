import { PropsWithChildren } from 'react';
import { Info } from '@phosphor-icons/react';

import { cn } from '../../_utils/cn';

import { Label } from './Label';
import { Text } from './Text';
import { Tooltip } from './Tooltip';

export type InputWrapperProps = PropsWithChildren<{
  labelText?: string;
  helperText?: string | React.ReactNode;
  errorText?: string | React.ReactNode;
  name?: string;
  className?: string;
  labelInfo?: string;
}>;

const InputWrapper = ({ labelText, helperText, children, name, className, errorText, labelInfo }: InputWrapperProps) => {
  return (
    <div className={cn('flex flex-col gap-1.5', className)}>
      <div className="flex items-center gap-1 h-4">
        {labelText && <Label htmlFor={name}>{labelText}</Label>}
        {labelInfo && <Tooltip center={labelInfo} >
          <Info size={16} className='text-wb-secondary' />
        </Tooltip>}
      </div>
      {children}
      {helperText && (
        <Text size="xs" weight="medium" variant="secondary" as="span">
          {helperText}
        </Text>
      )}
      {errorText && (
        <Text size="xs" weight="medium" variant="danger" as="span">
          {errorText}
        </Text>
      )}
    </div>
  );
};

export default InputWrapper;
