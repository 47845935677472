import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

interface ContentTreeContextType {
  isAllowDnD: boolean;
  pageLinkPrefix: string;
  isShowOptions: boolean;
  isShowAddPage: boolean;
  isLinkToPage?: boolean;
  rightComponent?: (id: string) => React.ReactNode;
  searchQuery?: string;
  isSearchMode?: boolean;
  onClick?: (id: string) => void;
}

const ContentTreeContext = createContext<ContentTreeContextType>({
  isAllowDnD: true,
  pageLinkPrefix: '/website_builder_v2/page',
  isShowOptions: true,
  isShowAddPage: true,
  isLinkToPage: true,
  searchQuery: '',
  isSearchMode: false,
});

export const useContentTreeContext = () => useContext(ContentTreeContext);
export const ContentTreeProvider = ({ children, isAllowDnD, pageLinkPrefix, isShowOptions, isShowAddPage, isLinkToPage = true, rightComponent, searchQuery, isSearchMode, onClick }: PropsWithChildren<ContentTreeContextType>) => {
  const value = useMemo(() => ({
    isAllowDnD,
    pageLinkPrefix,
    isShowOptions,
    isShowAddPage,
    isLinkToPage,
    rightComponent,
    searchQuery,
    isSearchMode,
    onClick,
  }), [isAllowDnD, pageLinkPrefix, isShowOptions, isShowAddPage, isLinkToPage, rightComponent, searchQuery, isSearchMode, onClick]);

  return <ContentTreeContext.Provider value={value}>
    {children}
  </ContentTreeContext.Provider>
}
