import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { AccordionCard } from "@/components/Accordion/variants/AccordionCard";
import { Select } from "@/components/Form";
import { PostEditorSteps } from "@/components/Layout/PostEditorLayout/constants";
import Multiselect from "@/components/Multiselect";
import { Typography } from "@/components/Typography";
import { useCreatePostTarget, useDeletePostTarget } from "@/hooks/usePostTargets";
import { Post, PostSendStatus } from "@/interfaces/post";
import { PostTargetAction, PostTargetPlatform } from "@/interfaces/post_target";
import { Tier } from "@/interfaces/tier";
import { Button } from "@/ui/Button";

interface Props {
  display: boolean;
  formData: Post;
  onChange: (data: any) => void;
  openWebSection: boolean;
  paidTiers: Tier[];
  refetchWebPostTargets: () => void;
  setOpenEmailSection: (open: boolean) => void;
  setOpenPublishToSection: (open: boolean) => void;
  setOpenWebSection: (open: boolean) => void;
  setPostEditorStep?: any;
  showEmailSection: boolean;
  tierOptions: { label: string; value: string }[];
  webPostSendTargets: any[];
}

const WebAudience = ({
  display,
  formData,
  onChange,
  openWebSection,
  paidTiers,
  refetchWebPostTargets,
  setOpenEmailSection,
  setOpenPublishToSection,
  setOpenWebSection,
  setPostEditorStep,
  showEmailSection,
  tierOptions,
  webPostSendTargets,
}: Props) => {
  const [webDefaultAudiencesQuery, setWebDefaultAudiencesQuery] = useState("");
  const navigate = useNavigate();

  const createWebPostTarget = useCreatePostTarget(formData?.id || "", PostTargetPlatform.WEB);
  const deletePostTarget = useDeletePostTarget(formData?.id || "");

  const handleDeselectWebAudience = (name: string, deselected: string) => {
    const postTarget = webPostSendTargets.find((target) => target.tier_id === deselected && target.receiver_type === "Publication");
    if (postTarget) {
      deletePostTarget.mutate(postTarget.id, {
        onSuccess: () => refetchWebPostTargets(),
      });
    }
  }

  const handleWebAudienceFooterButtonClick = () => {
    setOpenWebSection(false);
    if (setPostEditorStep) {
      if (showEmailSection) {
        setPostEditorStep(PostEditorSteps.EMAIL);
      } else {
        setPostEditorStep(PostEditorSteps.WEB);
      }
    }
  }

  const postSent = formData.send_status === PostSendStatus.SENT;

  return (
    <div className={display ? '' : 'hidden'}>
      <AccordionCard
        title="Web Audience"
          titleSize="base"
          titleWeight="medium"
          subText=""
          topBorder={false}
          isControlledState
          isControlledStateOpen={openWebSection}
          onClick={() => {
            if (!openWebSection) {
              setOpenWebSection(true);
              setOpenEmailSection(false);
              setOpenPublishToSection(false);
            } else if (showEmailSection) {
              setOpenEmailSection(true);
              setOpenWebSection(false);
            } else {
              setOpenPublishToSection(true);
              setOpenWebSection(false);
            }
          }}
          footer={
            <div className="flex px-6 py-3 justify-end items-center gap-3 self-stretch bg-surface-50 rounded-b-lg">
              <div className="flex flex-row gap-3">
                <Button onClick={() => {
                    if (showEmailSection) {
                      setOpenEmailSection(true);
                      setOpenWebSection(false);
                    } else {
                      setOpenPublishToSection(true);
                      setOpenWebSection(false);
                    }
                  }} variant="flush">Back</Button>
                <Button onClick={handleWebAudienceFooterButtonClick} type="button" variant="primary" size="sm">Continue</Button>
              </div>
            </div>
          }
          marginTop="mt-0"
      >
        <div className="flex flex-col gap-6">
          <Multiselect
            shouldCloseOnSelection={false}
            emptyLabel="Search and select"
            labelText="Default audiences"
            name="web-default-audiences"
            showClearAll={false}
            disabled={postSent}
            onDeselect={handleDeselectWebAudience}
            onSearchQueryChange={(query) => setWebDefaultAudiencesQuery(query)}
            onSearch={() => {
              const options = [
                {
                  label: "All Free Subscribers",
                  value: "free",
                  subtitle: `${webPostSendTargets.find((target) => target.tier === "free" && target.tier_id === "free")?.num_active_subscribers || 0} subscribers`,
                },
                {
                  label: "All Premium subscribers",
                  value: "premium",
                  subtitle: `${paidTiers.reduce((acc, val) => acc + (val.active_subscriptions_count || 0), 0)} subscribers`,
                  subItems: [
                    ...paidTiers.map((tier) => {
                      return {
                        label: `All ${tier.name}`,
                        value: tier.id,
                        subtitle: `${tier.active_subscriptions_count || 0} subscribers`,
                      }
                    }),
                  ],
                },
              ].filter(
                (option) => (
                  option.label.toLowerCase().includes(
                    webDefaultAudiencesQuery.toLowerCase()
                  ) ||
                  option.subItems?.some(
                    (subItem) => subItem.label.toLowerCase().includes(
                      webDefaultAudiencesQuery.toLowerCase()
                    )
                  )
                )
              );
              return new Promise((resolve) => {
                resolve(options);
              });
            }}
            onDeselectAll={() => {}}
            onSelect={(name, value) => {
              createWebPostTarget.mutate({
                params: {
                  action: PostTargetAction.INCLUDE,
                  receiver_type: "Publication",
                  receiver_id: formData?.publication_id || "",
                  tier_id: value,
                }
              }, {
                onSuccess: () => refetchWebPostTargets(),
              });
            }}
            values={
              webPostSendTargets.filter(
                (target) => target.receiver_type === "Publication" && target.action === PostTargetAction.INCLUDE
              ).map(
                (target) => (
                  {
                    value: target.tier_id,
                    label: target.receiver_display_name,
                    subtitle: `${target.num_active_subscribers} subscribers`,
                  }
                )
              ) || []
            }
          />
          {
            (webPostSendTargets.filter((target) => target.tier_id === "premium" && target.action === PostTargetAction.INCLUDE).length || 0) > 0 && tierOptions.length > 0 &&
            <Select
              name="paywall_break_price_id"
              labelText="Tier/Plan to display on the paywall"
              value={formData.paywall_break_price_id || ""}
              onSelect={(_name: string, value: string) => onChange({ paywall_break_price_id: value })}
              options={tierOptions}
              disabled={postSent}
            />

          }
          <Button onClick={() => navigate("/settings/publication/premium")} type="button" variant="flush" size="sm" className="w-fit">
            <Typography token="font-medium/text/xs" color="secondary" colorWeight="600" className="underline cursor-pointer">
                Manage tiers and plans
            </Typography>
          </Button>
        </div>
      </AccordionCard>
    </div>
  )
}

export default WebAudience;
