import React, { ElementType, PropsWithChildren } from 'react';
import { Icon as PhosphorIcon } from '@phosphor-icons/react';

import { cn } from '../../_utils/cn';

type Variants =
  | 'primary'
  | 'inverse'
  | 'secondary'
  | 'outlined'
  | 'accent-soft'
  | 'danger'
  | 'danger-soft'
  | 'success'
  | 'success-soft'
  | 'ghost';

type Sizes = 'sm' | 'md';

export type ButtonProps = PropsWithChildren<{
  /** React component for the left icon */
  LeftIcon?: PhosphorIcon | ElementType;
  /** Additional classname for the left icon. Tips: use Tailwind's classes */
  leftIconClassName?: string;
  /** React component for the icon replacing the text */
  Icon?: PhosphorIcon | ElementType;
  /** Additional classname for the icon replacing the text. Tips: use Tailwind's classes */
  iconClassName?: string;
  /** React component for the right icon */
  RightIcon?: PhosphorIcon | ElementType;
  /** Additional classname for the right icon. Tips: use Tailwind's classes */
  rightIconClassName?: string;
  /** Weight of the icon */
  iconWeight?: 'bold' | 'regular' | 'light' | 'duotone' | 'thin' | 'fill' | 'line';
  /** Show loading state with spinner */
  isLoading?: boolean;
  /** Text to show when in loading state */
  loadingText?: string;
  /** Placement of the loading spinner if `loadingText` exist */
  loadingPlacement?: 'left' | 'right';
  /** Variant of the button */
  variant?: Variants;
  /** Size of the button */
  size?: Sizes;
  /** Disable the button */
  isDisabled?: boolean;
  /** Click handler */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /** Additional classname */
  className?: string;
  /** Type of button */
  type?: 'button' | 'submit';
}>;

const colorClasses: Record<Variants, string> = {
  primary: 'bg-wb-accent hover:bg-wb-accent-600 active:bg-wb-accent-600 text-white',
  inverse: 'bg-wb-inverse hover:bg-wb-inverse-secondary active:bg-wb-inverse-secondary text-wb-invert-primary',
  secondary: 'bg-wb-secondary hover:bg-wb-tertiary active:bg-wb-tertiary text-wb-primary',
  outlined: 'bg-wb-primary hover:bg-wb-secondary active:bg-wb-secondary border border-wb-primary text-wb-primary',
  'accent-soft': 'bg-wb-button-accent-soft hover:bg-wb-accent-100 active:bg-wb-accent-50 text-wb-accent',
  danger: 'bg-wb-button-danger hover:bg-red-600 active:bg-red-600 text-white',
  'danger-soft': 'bg-wb-button-danger-soft hover:bg-red-100 active:bg-red-100 text-wb-danger',
  success: 'bg-wb-button-success hover:bg-emerald-600 active:bg-emerald-600 text-white',
  'success-soft': 'bg-wb-button-success-soft hover:bg-emerald-100 active:bg-emerald-100 text-wb-success',
  ghost: 'bg-transparent hover:bg-wb-secondary active:bg-wb-secondary text-wb-secondary',
};

const iconClasses: Record<Sizes, string> = {
  sm: 'w-3 h-3',
  md: 'w-4 h-4',
};

const sizeClasses: Record<Sizes, string> = {
  sm: 'px-2 py-1.5 text-xs gap-1',
  md: 'p-2.5 text-sm gap-1',
};

const disabledClasses = 'cursor-not-allowed opacity-50';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center w-6 h-6">
    <svg className="animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} fill="none" />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </div>
);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'primary',
      size = 'md',
      isDisabled,
      children,
      LeftIcon,
      leftIconClassName,
      Icon,
      iconClassName,
      RightIcon,
      rightIconClassName,
      iconWeight,
      isLoading,
      loadingText,
      onClick,
      type = 'button',
      ...props
    },
    ref
  ) => {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={cn(
          'flex items-center justify-center gap-2 rounded-lg transition-colors duration-200 focus:outline-none',
          colorClasses[variant],
          sizeClasses[size],
          isDisabled ? disabledClasses : '',
          className
        )}
        ref={ref}
        data-disabled={isDisabled}
        disabled={isDisabled || isLoading}
        onClick={(e) => (onClick && !isDisabled && !isLoading ? onClick(e) : undefined)}
        {...props}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          LeftIcon && (
            <LeftIcon
              weight={iconWeight || 'bold'}
              className={cn('fill-current leading-none', iconClasses[size], leftIconClassName)}
            />
          )
        )}
        {Icon && (
          <Icon
            weight={iconWeight || 'bold'}
            className={cn('fill-current leading-none', iconClasses[size], iconClassName)}
          />
        )}
        {isLoading ? loadingText : children}
        {RightIcon && (
          <RightIcon
            weight={iconWeight || 'bold'}
            className={cn('fill-current leading-none', iconClasses[size], rightIconClassName)}
          />
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export { Button };
