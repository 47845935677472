import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDownIcon, SpeakerWaveIcon } from '@heroicons/react/24/solid';

import { AccordionCard } from '@/components/Accordion/variants/AccordionCard';
import { Input, Select, SimpleSelect, Switch, Textarea } from '@/components/Form';
import Modal from '@/components/Modal';
import { SEOPreview } from '@/components/SEOPreview';
import SortFeaturedPosts from '@/components/SortableList/SortableLists/SortFeaturedPosts';
import Tooltip from '@/components/Tooltip';
import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import {
  useCurrentPublication,
  useElevenlabsVoices,
  usePostTextToSpeechConfig,
  useUpdatePostTextToSpeechConfig,
  useUpdateWebTemplate,
  useWebTemplate,
} from '@/hooks';
import { ElevenlabsVoice, PartialPost, Post, TextToSpeechConfig } from '@/interfaces/post';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import { usePostContext } from '../PostContext';

type FeaturedPosts = Array<Post | PartialPost>;

interface FeaturedPostModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  featuredPosts: FeaturedPosts[] | any;
  handleItemRemoved: any;
  handleItemsOrdered: any;
}

const DEFAULTS = {
  THUMBNAIL_URL: 'https://media.beehiiv.com/static_assets/publish_on_beehiiv.png',
};

const FeaturedPostModal = ({
  isOpen,
  onClose,
  onSave,
  featuredPosts,
  handleItemRemoved,
  handleItemsOrdered,
}: FeaturedPostModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} includeCloseButton={false} className="max-w-xl w-full">
      <div className="max-w-2xl w-full rounded">
        <div className="px-6 pt-6 pb-4 flex flex-col gap-6">
          <TypographyStack>
            <Typography token="font-medium/text/lg">Featured Posts</Typography>
            <Typography token="font-light/text/xs">
              You may feature up to 6 posts on your website&apos;s homepage. You have currently featured 5 posts
            </Typography>
          </TypographyStack>
          <div className="w-full">
            <SortFeaturedPosts
              featuredPosts={[...featuredPosts]}
              onItemRemoved={(newList) => handleItemRemoved(newList)}
              onItemsOrdered={(newList) => handleItemsOrdered(newList)}
            />
          </div>
        </div>
        <div className="px-6 py-3 flex flex-row justify-end gap-3">
          <Button onClick={onClose} variant="primary-inverse" size="sm">
            Cancel
          </Button>
          <Button onClick={onSave} variant="primary" size="sm">
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const Web = () => {
  const { formData, onChange } = usePostContext();
  const postId = formData?.id || '';
  const { data: textToSpeechConfigData, isLoading, refetch } = usePostTextToSpeechConfig(formData?.id || '');
  const { data: voicesData = [] } = useElevenlabsVoices();
  const voices = Array.isArray(voicesData) ? voicesData : [];
  const [newList, setNewList] = useState<any>([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isFeaturedPostModalOpen, setIsFeaturedPostModalOpen] = useState(false);
  const { data: webTemplate } = useWebTemplate();
  const { data: publication } = useCurrentPublication();
  const webTemplateMutation = useUpdateWebTemplate();
  const [textToSpeechConfig, setTextToSpeechConfig] = useState<TextToSpeechConfig | undefined>(undefined);

  const featuredPosts = useMemo(() => webTemplate?.feature_post_ids || [], [webTemplate]);
  const featuredPostsCount = featuredPosts.length;
  const isPostFeatured = Boolean(featuredPosts.find((featuredPost: Post) => featuredPost?.id === formData?.id));

  const [isOgAccordionOpen, setIsOgAccordionOpen] = useState(false);
  const [isTwitterAccordionOpen, setIsTwitterAccordionOpen] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setTextToSpeechConfig(textToSpeechConfigData);
    }
  }, [textToSpeechConfigData, isLoading]);

  const postTextToSpeechConfigMutation = useUpdatePostTextToSpeechConfig({ postId, onError: refetch });

  const onChangeTextToSpeechConfig = useCallback(
    async (data: any) => {
      setTextToSpeechConfig({ ...textToSpeechConfig, ...data });

      await postTextToSpeechConfigMutation.mutateAsync(data);

      refetch();
    },
    [textToSpeechConfig, postTextToSpeechConfigMutation, refetch]
  );

  useEffect(() => {
    setIsFeatured(isPostFeatured);
  }, [isPostFeatured]);

  const voiceSamplePlayer = useRef<Howl>();
  const playSample = useCallback((voice: ElevenlabsVoice) => {
    voiceSamplePlayer.current?.unload();

    const howl = new Howl({
      src: [voice.preview_url],
      loop: false,
      volume: 0.5,
    });

    howl.play();

    voiceSamplePlayer.current = howl;
  }, []);

  if (!formData) {
    return null;
  }

  const searchEngineUrl = formData.custom_live_url || formData.url;

  let hostname = '';

  try {
    const url = new URL(searchEngineUrl);
    hostname = url.hostname;
  } catch {
    hostname = '';
  }

  const buildPostsPayload = (value: boolean) => {
    let newFeaturedPosts = [...featuredPosts];

    if (value) {
      newFeaturedPosts.push(formData);
    } else {
      newFeaturedPosts = newFeaturedPosts.filter((featuredPost) => featuredPost.id !== formData.id);
    }

    return {
      feature_post_ids: newFeaturedPosts.map((featuredPost) => featuredPost.id),
    };
  };

  const onFeaturedSelected = async (value: boolean) => {
    setIsFeatured(value);
    if (featuredPostsCount >= 6 && value) {
      setIsFeaturedPostModalOpen(true);
    } else {
      await webTemplateMutation.mutateAsync(buildPostsPayload(value));
    }
  };

  const handleItemRemoved = (updatedList: string[]) => {
    const newFeaturedPosts = featuredPosts.filter((featuredPost: Post) => updatedList.includes(featuredPost.id));
    setNewList(newFeaturedPosts);
  };

  const handleSave = async () => {
    await webTemplateMutation
      .mutateAsync({
        feature_post_ids: newList.map((featuredPost: Post) => featuredPost.id),
      })
      .then(() => {
        setIsFeaturedPostModalOpen(false);
        setNewList(featuredPosts);
      });
  };

  const handleItemsOrdered = async (updatedList: string[]) => {
    const orderedFeaturedPosts = updatedList.map((id: string) =>
      featuredPosts.find((featuredPost: Post) => featuredPost.id === id)
    );
    setNewList(orderedFeaturedPosts);
  };

  let publicationThumbnail = DEFAULTS.THUMBNAIL_URL;
  if (
    typeof publication?.thumbnail.url === 'string' &&
    !publication?.thumbnail.url.endsWith('defaults/thumbnail.png')
  ) {
    publicationThumbnail = publication?.thumbnail?.url;
  }
  const seoPreviewImage = formData.thumbnail?.url || publicationThumbnail;

  return (
    <div className="px-2 md:px-20 py-10 flex flex-col gap-6">
      <FeaturedPostModal
        isOpen={isFeaturedPostModalOpen}
        onClose={() => {
          setIsFeaturedPostModalOpen(false);
          setNewList(featuredPosts);
        }}
        onSave={handleSave}
        featuredPosts={newList}
        handleItemRemoved={handleItemRemoved}
        handleItemsOrdered={handleItemsOrdered}
      />
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6">
        <Typography token="font-medium/text/base">Web post settings</Typography>
        <Input
          name="slug"
          value={formData.slug}
          required
          leadingText={`${hostname}/p/`}
          labelText="Post URL"
          placeholder="new-post"
          onChange={(e) => onChange({ slug: e.target.value })}
          className="py-2"
        />
        <Select
          name="email_capture_type"
          value={formData.email_capture_type}
          labelText="Advanced email capture"
          suffixElement={<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
          options={[
            { label: 'None', value: 'none' },
            { label: 'Gated', value: 'gated' },
            { label: 'Popup', value: 'popup' },
          ]}
          onSelect={(name, value) => onChange({ email_capture_type_override: value, email_capture_type: value })}
        />
        <Select
          labelText="Comments"
          name="comments_state"
          value={formData.comments_state}
          suffixElement={<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
          options={[
            { label: 'All subscribers', value: 'default' },
            { label: 'Premium subscribers only', value: 'premium' },
            { label: 'Disabled: Hide comments section', value: 'disabled' },
            { label: 'Locked: Show comments section but disable new comments', value: 'locked' },
          ]}
          onSelect={(name, value) => onChange({ comments_state: value })}
        />
      </Card>
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6">
        <div className="flex flex-row gap-2 items-center">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-3">
              <Typography token="font-medium/text/base">Audio newsletter</Typography>
              <Tooltip
                id={String(Math.random())}
                text="Audio will capture the entire post content, regardless of post visibility settings. It might take 5–10 minutes to generate the audio transcript."
              />
            </div>
            <Typography token="font-normal/text/sm">
              Readers will see a &apos;listen online&apos; option in email header to access the audio transcript at the
              top of the published web post.
            </Typography>
          </div>
          <Switch
            name="audio_newsletter"
            checked={textToSpeechConfig?.enabled || false}
            onChange={(name, e) => onChangeTextToSpeechConfig({ enabled: e })}
            variant="primary"
            showIcons={false}
          />
        </div>
        {textToSpeechConfig?.enabled && (
          <SimpleSelect
            name="elevenlabs-voice-id"
            labelText="Choose Voice"
            required
            value={textToSpeechConfig?.voice_id || ''}
            onSelect={(_name: string, value: string) => onChangeTextToSpeechConfig({ voice_id: value })}
            options={voices?.map((v) => {
              return {
                value: v.voice_id,
                label: v.name,
                optionAction: (
                  <SpeakerWaveIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      playSample(v);
                    }}
                    className="h-4 w-4 cursor-pointer text-surface-500 hover:text-surface-700"
                  />
                ),
              };
            })}
            emptyLabel="No voices available"
            buttonClassNames={{'focus': 'focus:ring-primary-500 focus:border-primary-500'}}
            placeholderText="Select a Voice"
          />
        )}
      </Card>
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6">
        <Typography token="font-medium/text/base">SEO settings</Typography>
        <div>
          <div className="flex flex-row justify-between">
            <Typography token="font-medium/text/sm">Meta title</Typography>
            <Typography token="font-normal/text/xs">
              You&apos;ve used{' '}
              <Typography token="font-medium/text/xs" color={(formData.meta_default_title?.length || 0) > 60 ? 'warning' : 'secondary'} colorWeight="600">
                {formData.meta_default_title?.length || 0}
              </Typography>{' '}
              characters
            </Typography>
          </div>
          <Input
            name="meta-title"
            value={formData.meta_default_title || ''}
            placeholder="New Post"
            onChange={(e) => onChange({ meta_default_title: e.target.value })}
            className="py-2"
          />
          <Typography token="font-light/text/xs">
            Recommended: <Typography token="font-medium/text/xs">60</Typography> characters
          </Typography>
        </div>
        <div>
          <div className="flex flex-row justify-between">
            <Typography token="font-medium/text/sm">Meta description</Typography>
            <Typography token="font-normal/text/xs">
              You&apos;ve used{' '}
              <Typography token="font-medium/text/xs" color={(formData.meta_default_description?.length || 0) > 145 ? 'warning' : 'secondary'} colorWeight="600">
                {formData.meta_default_description?.length || 0}
              </Typography>{' '}
              characters
            </Typography>
          </div>
          <Textarea
            name="meta-description"
            value={formData.meta_default_description || ''}
            placeholderText="Enter description"
            onChange={(e) => onChange({ meta_default_description: e.target.value })}
            className="py-2"
          />
          <Typography token="font-light/text/xs">
            Recommended: <Typography token="font-medium/text/xs">145</Typography> characters
          </Typography>
        </div>
        <div className="flex flex-col gap-2">
          <Typography token="font-medium/text/sm">Search engine result preview</Typography>
          <div className="p-4 flex flex-col gap-2 border rounded-md border-surface-200">
            <Typography token="font-medium/text/xs">
              {hostname} › {formData.slug}
            </Typography>
            <div className="flex flex-col gap-1">
              <Typography color="tertiary" size="xl" className="leading-6">
                {formData.meta_default_title || 'New Post'}
              </Typography>
              <Typography token="font-normal/text/sm">{formData.meta_default_description || ''}</Typography>
            </div>
          </div>
        </div>
        <AccordionCard
          title="Open Graph (Facebook and LinkedIn)"
          titleSize="base"
          titleWeight='medium'
          subText=""
          topBorder={false}
          isControlledState
          isControlledStateOpen={isOgAccordionOpen}
          onClick={() => {
            if (!isOgAccordionOpen) {
              setIsOgAccordionOpen(true);
              if (isTwitterAccordionOpen) setIsTwitterAccordionOpen(false);
            } else {
              setIsOgAccordionOpen(false);
            }
          }}
        >
          <div className="flex flex-col gap-4">
            <Input
              labelText="Meta title"
              name="meta-og-title"
              value={formData.meta_og_title || ''}
              placeholder="New Post"
              onChange={(e) => onChange({ meta_og_title: e.target.value })}
              className="py-2"
            />
            <Textarea
              labelText="Meta description"
              name="meta-description"
              value={formData.meta_og_description || ''}
              placeholderText="Enter description"
              onChange={(e) => onChange({ meta_og_description: e.target.value })}
              className="py-2"
            />
            <SEOPreview
              title={formData.meta_og_title || 'New Post'}
              description={formData.meta_og_description || ''}
              url={formData.url}
              imageUrl={seoPreviewImage}
              variant="facebook"
            />
          </div>
        </AccordionCard>
        <AccordionCard
          title="X (previously Twitter)"
          titleSize="base"
          titleWeight='medium'
          subText=""
          topBorder={false}
          isControlledState
          isControlledStateOpen={isTwitterAccordionOpen}
          onClick={() => {
            if (!isTwitterAccordionOpen) {
              setIsTwitterAccordionOpen(true);
              if (isOgAccordionOpen) setIsOgAccordionOpen(false);
            } else {
              setIsTwitterAccordionOpen(false);
            }
          }}
        >
          <div className="flex flex-col gap-4">
            <Input
              labelText="Meta title"
              name="meta-og-title"
              value={formData.meta_twitter_title || ''}
              placeholder="New Post"
              onChange={(e) => onChange({ meta_twitter_title: e.target.value })}
              className="py-2"
            />
            <Textarea
              labelText="Meta description"
              name="meta-description"
              value={formData.meta_twitter_description || ''}
              placeholderText="Enter description"
              onChange={(e) => onChange({ meta_twitter_description: e.target.value })}
              className="py-2"
            />
            <SEOPreview
              title={formData.meta_twitter_title || 'New Post'}
              description={formData.meta_twitter_description || ''}
              url={formData.url}
              imageUrl={seoPreviewImage}
              variant="twitter"
            />
          </div>
        </AccordionCard>
      </Card>
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6">
        <Typography token="font-medium/text/base">Post Visibility</Typography>
        <div>
          <Switch
            name="hide-post-from-feed"
            checked={formData.hide_from_feed}
            onChange={(name, e) => onChange({ hide_from_feed: e })}
            prefixLabelText="Hide the post from feed"
            variant="primary"
            showIcons={false}
          />
        </div>
        <div>
          <Switch
            name="feature-the-post"
            checked={isFeatured}
            onChange={(name, e) => onFeaturedSelected(e)}
            prefixLabelText="Feature the post"
            variant="primary"
            showIcons={false}
            disabled={featuredPosts.length >= 6 && !isFeatured}
            helperText={
              <TypographyRow>
                <Typography token="font-light/text/xs">
                  You may feature up to 6 posts on your website&apos;s homepage. You have currently featured{' '}
                  {featuredPosts.length} {featuredPosts.length === 1 ? 'post' : 'posts'}.
                </Typography>
                <div
                  role="button"
                  onClick={() => {
                    setNewList(featuredPosts);
                    setIsFeaturedPostModalOpen(true);
                  }}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setNewList(featuredPosts);
                      setIsFeaturedPostModalOpen(true);
                    }
                  }}
                  className="cursor-pointer"
                >
                  <Typography
                    className="cursor-pointer"
                    token="font-medium/text/xs"
                    color="secondary"
                    colorWeight="600"
                  >
                    Manage featured posts
                  </Typography>
                </div>
              </TypographyRow>
            }
          />
        </div>
      </Card>
    </div>
  );
};

export default Web;
