

import Navigation from './Navigation';

interface Props {
  largeNavOpen: boolean;
}

const Navbar = ({ largeNavOpen }: Props) => {
  return (
    <Navigation largeNavOpen={largeNavOpen} className="hidden sm:flex" />
  );
};

export default Navbar;
