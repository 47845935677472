import { useEffect, useRef, useState } from 'react';

import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { Button } from '@/components/TiptapEditor/components/ui/Button';
import Checkbox from '@/components/TiptapEditor/components/ui/Checkbox';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { InputField } from '@/components/TiptapEditor/components/ui/Input';
import { Panel, PanelDivider, PanelHeader, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { Author } from '@/interfaces/author';
import { Post } from '@/interfaces/post';
import { normalizeString } from '@/utils';

interface Props {
  onBack: () => void;
  post: Post;
  updatePost: (data: Post) => void;
  users: Author[];
  setShowCreateGuestAuthorModal: (value: boolean) => void;
}

const AddGuestAuthorMenu = ({ onBack, post, users, updatePost, setShowCreateGuestAuthorModal }: Props) => {
  const guestAuthorIdsRef = useRef<string | undefined>();
  const [searchResults, setSearchResults] = useState<{ label: string; value: string }[]>([]);
  const [selectedAuthors, setSelectedAuthors] = useState<string[]>(post.guest_author_ids);
  const derivedGuestAuthorIds = JSON.stringify(users.map((user) => user.id));

  useEffect(() => {
    updatePost({ ...post, guest_author_ids: selectedAuthors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAuthors]);

  useEffect(() => {
    if (guestAuthorIdsRef.current !== derivedGuestAuthorIds) {
      setSearchResults(users.map((user) => ({ label: user.name, value: user.id })) || []);
    }
    guestAuthorIdsRef.current = derivedGuestAuthorIds;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedGuestAuthorIds]);

  const onAuthorSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e?.target?.value;
    const results =
      users
        ?.filter((filteredUser) => normalizeString(filteredUser.name).includes(query))
        .map((filteredUser) => ({ label: filteredUser.name, value: filteredUser.id })) || [];

    setSearchResults(results);
  };

  const onAuthorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const authorId = e.target.name;

    if (!checked) {
      setSelectedAuthors((prev) => prev.filter((author) => author !== authorId));
    } else {
      setSelectedAuthors((prev) => [...prev, authorId]);
    }
  };

  return (
    <Styled.ContentWrapper>
      <Panel>
        <PanelHeader>
          <Button
            $variant="quaternary"
            $size="small"
            $leftSlot={<Icon name="ChevronLeft" />}
            onClick={onBack}
            $fullWidth
          >
            Add Guest Author
          </Button>
        </PanelHeader>
        <PanelSection>
          <Styled.PanelSectionInner>
            <InputField onChange={onAuthorSearch} placeholder="Search and select" />
          </Styled.PanelSectionInner>
          <Styled.PanelSectionInner>
            <div className="mt-2">
              {searchResults?.length > 0 && (
                <ul>
                  {searchResults?.map((author) => {
                    return (
                      <li
                        key={author.value}
                        className="flex cursor-pointer hover:bg-white hover:bg-opacity-10 rounded-md mt-[.1rem] px-[0.3125rem] py-[0.375rem]"
                      >
                        <Checkbox
                          id={`author-${author.value}-label`}
                          name={author.value}
                          onChange={onAuthorChange}
                          checked={selectedAuthors.includes(author.value)}
                        />
                        <label
                          htmlFor={`author-${author.value}-label`}
                          className="ml-2 text-sm text-white cursor-pointer"
                        >
                          {author.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              )}
              {!searchResults ||
                (searchResults?.length === 0 && (
                  <ul>
                    <li className="flex rounded-md mt-[.1rem] px-[0.3125rem] py-[0.375rem]">No guest authors found.</li>
                  </ul>
                ))}
            </div>
          </Styled.PanelSectionInner>
          <PanelDivider />
          <Button
            $variant="quaternary"
            $size="small"
            onClick={() => {
              setShowCreateGuestAuthorModal(true);
            }}
            $fullWidth
            $rightSlot={
              <span className="ml-auto mr-0">
                <Icon name="Plus" />
              </span>
            }
          >
            <span className="w-full">Create guest author</span>
          </Button>
          <Button
            $variant="quaternary"
            $size="small"
            onClick={() => {
              window.location.href = `${window.location.origin}/guest_authors`;
            }}
            $fullWidth
            $rightSlot={
              <span className="ml-auto mr-0">
                <Icon name="External" />
              </span>
            }
          >
            <span className="w-full">Manage guest authors</span>
          </Button>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default AddGuestAuthorMenu;
