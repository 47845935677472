import { useState } from 'react';

import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { useThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelDivider, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { Post } from '@/interfaces/post';

import VisibilitySettings from './VisibilitySettings';

interface Props {
  post: Post;
  updatePost: (data: Post) => void;
}

const SubtitleActionsMenu = ({ post, updatePost }: Props) => {
  const [showVisibilitySettings, setShowVisibilitySettings] = useState(false);
  const { openComposer } = useThreadComposer();

  const handleOpenComposer = () => {
    openComposer();
  };

  if (showVisibilitySettings) {
    return <VisibilitySettings onBack={() => setShowVisibilitySettings(false)} post={post} updatePost={updatePost} />;
  }

  return (
    <Styled.ContentWrapper>
      <Panel tabIndex={-1}>
        <PanelSection>
          <Styled.MenuButtonWrapper>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={handleOpenComposer}
              $fullWidth
              $leftSlot={<Icon name="Comment" />}
              $muted
            >
              Comment
            </Button>
            <PanelDivider />
            <Button
              $variant="quaternary"
              $size="small"
              onClick={() => {
                setShowVisibilitySettings(true);
              }}
              $fullWidth
              $leftSlot={<Icon name="Show" />}
              $rightSlot={<Icon name="ChevronRight" />}
            >
              Visibility
            </Button>
          </Styled.MenuButtonWrapper>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default SubtitleActionsMenu;
