import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Badge } from '@tremor/react';
import cx from 'classnames';

import { SortableList, SortableListItem, useSortableList } from '@/components/SortableList';
import { useWebsiteContext } from '@/context/website-context';
import { SignupFlow } from '@/interfaces/signup_flow';

import { Button } from '../../../_components/UI/Button';
import ComboboxInput, { ComboboxOption } from '../../../_components/UI/ComboboxInput';
import { Input } from '../../../_components/UI/Input';
import InputWrapper from '../../../_components/UI/InputWrapper';
import Modal from '../../../_components/UI/Modal';
import { Text } from '../../../_components/UI/Text';

interface Props {
  signupFlow?: SignupFlow | null;
  isSubmitting: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (payload: { name: string; urls: string[] }) => void;
  publicationUrl: string;
}

type UrlItem = {
  id: string;
  value: string;
};

interface RoutesComboBoxProps {
  publicationUrl: string;
  selectedOption: any;
  setSelectedOption: (selected: string) => void;
  onInputChange: (query: string) => void;
  onSubmit?: () => void;
}

const RoutesComboBox = ({
  publicationUrl,
  selectedOption,
  setSelectedOption,
  onInputChange,
  onSubmit,
}: RoutesComboBoxProps) => {
  const { pagesRoutes, defaultRoutes } = useWebsiteContext();

  const allOptions: ComboboxOption[] = useMemo(() => {
    const defaultOptions: ComboboxOption[] =
      defaultRoutes?.children_keys?.map((route) => ({
        id: `${publicationUrl}${route}`,
        name: route,
      })) || [];

    const pageOptions: ComboboxOption[] =
      pagesRoutes?.children_keys?.map((route) => ({
        id: `${publicationUrl}${route}`,
        name: route,
      })) || [];

    return [...defaultOptions, ...pageOptions];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesRoutes, defaultRoutes]);

  return (
    <ComboboxInput
      options={allOptions}
      selectedOption={selectedOption}
      onInputChange={(query: string) => onInputChange(query)}
      onChange={(selected: any) => {
        setSelectedOption(selected.id);
      }}
      placeholder="https://new-url.com/"
      onKeyPress={(e) => {
        if (e.key === 'Enter' && onSubmit) {
          console.log('im here!!');
          e.preventDefault();
          onSubmit();
        }
      }}
    />
  );
};

const SignupFlowModal = ({ signupFlow, onClose, onSubmit, isSubmitting, isOpen, publicationUrl }: Props) => {
  const [name, setName] = useState(signupFlow?.name || '');
  const [newUrl, setNewUrl] = useState('');
  const [urls, setUrls] = useState(signupFlow?.steps.map((step) => step.url) || []);
  const [mounted, setMounted] = useState(false);

  const { sortableList, setSortableList, reStringifyList } = useSortableList<UrlItem>({
    list: urls,
    addIdToListOfStrings: true,
    zeroBasedIndex: false,
  });

  useEffect(() => {
    if (signupFlow && !mounted) {
      setName(signupFlow.name);
      setUrls(signupFlow.steps.map((step) => step.url));
      setMounted(true);
    }
  }, [signupFlow, mounted]);

  const handleSubmit = () => {
    const strinfifiedList = reStringifyList(sortableList);
    onSubmit({ name, urls: strinfifiedList });
  };

  const onAddUrl = () => {
    try {
      const isUrl = new URL(newUrl);
      if (isUrl) {
        setUrls([...urls, newUrl]);
        setNewUrl('');
      }
    } catch (error) {
      toast.error('Invalid URL');
    }
  };

  const title = signupFlow ? 'Edit Signup Flow' : 'Create Signup Flow';
  const ctaText = signupFlow ? 'Save' : 'Create';
  const isLoadingText = signupFlow ? 'Saving...' : 'Creating...';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      onConfirm={handleSubmit}
      isLoading={isSubmitting}
      ctaText={ctaText}
      isLoadingText={isLoadingText}
    >
      <div className="flex flex-col space-y-4">
        <Input
          name="signup_flow_name"
          labelText="Name"
          placeholder="New subscriber flow"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <div className="flex flex-col space-y-1">
          <InputWrapper
            labelText="Steps"
            helperText="Add urls and rearange them for your desired signup flow. External urls can only be places as the last destination in a sequence."
          >
            <SortableList
              listItems={sortableList}
              onItemsSorted={(sortedList: any[]) => {
                setSortableList(sortedList);
              }}
            >
              {(list) => {
                return list.map((item: UrlItem) => {
                  const isExternal = !item.value.includes(publicationUrl);

                  return (
                    <SortableListItem
                      key={item.id}
                      listItem={item}
                      className={cx(
                        '!bg-wb-primary !font-medium !text-sm !rounded-lg !h-10 !shadow-sm !border-wb-primary'
                      )}
                      onRemoveItem={(urlItem: UrlItem) => {
                        const filteredUrls = list.filter((listItem: UrlItem) => listItem.id !== urlItem.id);
                        setUrls(filteredUrls.map((lItem: any) => lItem.value));
                      }}
                      text={
                        <div className="flex items-center space-x-2">
                          <Text
                            size="xs"
                            variant="primary-soft"
                            className="tracking-tight !font-medium truncate max-w-[300px]"
                            weight="medium"
                            as="span"
                          >
                            {item.value}
                          </Text>
                          {isExternal && (
                            <Badge size="sm">
                              <span className="text-xs">External</span>
                            </Badge>
                          )}
                        </div>
                      }
                    />
                  );
                });
              }}
            </SortableList>
            <div className={cx('flex flex-col space-y-2')}>
              <div className="flex items-center space-x-2">
                <RoutesComboBox
                  publicationUrl={publicationUrl}
                  selectedOption={newUrl}
                  onInputChange={setNewUrl}
                  setSelectedOption={(url: string) => {
                    setUrls([...urls, url]);
                  }}
                  onSubmit={onAddUrl}
                />
                <Button
                  variant="outlined"
                  className="whitespace-nowrap"
                  onClick={() => {
                    onAddUrl();
                  }}
                >
                  Add url
                </Button>
              </div>
            </div>
          </InputWrapper>
        </div>
      </div>
    </Modal>
  );
};

export default SignupFlowModal;
