import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { SkeletonLoader } from '@/components/SkeletonLoader';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentPublication } from '@/hooks';
import useClickedOutside from '@/hooks/useClickedOutside';
import usePublications from '@/hooks/usePublications/usePublications';
import { Publication } from '@/interfaces/publication';

import PublicationOptions from './PublicationOptions';

interface Props {
  largeNavOpen: boolean;
}

const PublicationDropdown = ({ largeNavOpen }: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  // Close dropdown if mouse clicks outside the dropdown
  // are captured
  useClickedOutside(menuRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const { data: publications, isLoading: isLoadingPublications } = usePublications();
  const { data: currentPublication } = useCurrentPublication();
  const [currentPublicationId, setCurrentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const hasMultiplePublications = publications?.length > 1;

  const onSelectOption = (publication: Publication) => {
    if (currentPublicationId !== publication.id) {
      setCurrentPublicationId(publication.id);
      queryClient.invalidateQueries();
      navigate('/');
    }

    setIsOpen(false);
  };

  const isLoading = isLoadingPublications || !currentPublication;

  const onToggleDropdown = () => {
    if (isLoading || !hasMultiplePublications) {
      return;
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-col">
      <div className="p-2">
        <SkeletonLoader
          isLoading={isLoading}
          skeletons={
            <div className="flex items-center justify-end">
              <div className="bg-gray-200 h-8 w-full rounded" />
            </div>
          }
          useFragment
        >
          {currentPublication && (
            <div className={cx('relative flex flex-col space-y-4')}>
              <div className={cx('relative', !largeNavOpen && 'flex flex-col justify-center items-center')}>
                <button
                  aria-expanded={isOpen}
                  aria-haspopup="true"
                  className={cx(
                    'flex-grow-0 relative bg-surface-50 border border-surface-100 rounded-md text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm',
                    hasMultiplePublications ? 'cursor-pointer' : 'cursor-default',
                    largeNavOpen ? 'pl-3 pr-4 py-2 w-full' : 'w-fit p-2'
                  )}
                  type="button"
                  onClick={onToggleDropdown}
                >
                  <div className="flex items-center">
                    <div className="w-5 h-5 min-w-5 min-h-5 rounded overflow-hidden">
                      {currentPublication.logo.thumb.url ? (
                        <img className="h-5 w-5" src={currentPublication.logo.thumb.url} alt="" />
                      ) : (
                        <div className="bg-gray-200 h-4 w-4 rounded" />
                      )}
                    </div>
                    {largeNavOpen && (
                      <>
                        <div className="truncate mr-2 flex-1">
                          <span className="ml-2 block truncate">{currentPublication.name}</span>
                        </div>
                        {hasMultiplePublications && (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </button>
                {publications && (
                  <div ref={menuRef}>
                    <PublicationOptions
                      currentPublicationId={currentPublication.id}
                      largeNavOpen={largeNavOpen}
                      publications={publications}
                      isOpen={isOpen}
                      onSelectOption={onSelectOption}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </SkeletonLoader>
      </div>
      <hr />
    </div>
  );
};

export default PublicationDropdown;
