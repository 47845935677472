import { Layout as LayoutIcon, Plus } from '@phosphor-icons/react';

import { useWebsiteContext, WebsiteProvider } from '@/context/website-context';

import { ContentTree } from '../_components/ContentTree';
import { ContentTreeProvider } from '../_components/ContentTree/context';
import { Layout } from '../_components/Layout';
import { ActionMenu } from '../_components/Layout/ActionMenu';
import { NavSection } from '../_components/SideNav/NavSection';
import { NavSectionTitle } from '../_components/SideNav/NavSectionTitle';
import { Text } from '../_components/UI/Text';


const PageEditorPage = () => {
  const { pagesRoutes, defaultRoutes } = useWebsiteContext();
  return (
    <Layout
      showLogoDropDown
      isSidebarResizable
      leftChildren={<>
        <ActionMenu Icon={Plus} text="Insert" onClick={() => { }} />
        <ActionMenu Icon={LayoutIcon} text="Templates" onClick={() => { }} />
      </>}
      sidenavChildren={
        <>
          <NavSection>
            <NavSectionTitle title="Pages" />
            {pagesRoutes && <ContentTreeProvider
              isAllowDnD
              isShowOptions
              isShowAddPage
              pageLinkPrefix="/website_builder_v2/page"
            >
              <ContentTree route={pagesRoutes} parentPath={[]} slug="" />
            </ContentTreeProvider>}
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Default Pages" />
            {defaultRoutes && <ContentTreeProvider
              isAllowDnD={false}
              isShowOptions
              isShowAddPage
              pageLinkPrefix="/website_builder_v2/page"
            >
              <ContentTree showHome={false} route={defaultRoutes} parentPath={[]} slug="" />
            </ContentTreeProvider>
            }
          </NavSection>
        </>
      }
      titleType="page_name"
    >
      <Text variant="secondary" size="xl" weight="semibold">
        Editor coming soon...{' '}
      </Text>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <PageEditorPage />
  </WebsiteProvider>
);
