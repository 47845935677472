import Tippy from '@tippyjs/react';

import { useThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Author } from '@/interfaces/author';
import { Post } from '@/interfaces/post';

import AuthorsActionsMenu from '../AuthorsMenu/AuthorsActionsMenu';

interface Props {
  post: Post;
  updatePost: (data: Post) => void;
  guestAuthors: Author[];
  guestAuthorId?: string;
  users: Author[];
  userId?: string;
  setShowCreateGuestAuthorModal: (value: boolean) => void;
}

const AuthorsTooltipMenu = ({
  post,
  updatePost,
  users,
  guestAuthors,
  guestAuthorId,
  setShowCreateGuestAuthorModal,
  userId,
}: Props) => {
  const { openComposer } = useThreadComposer();

  const handleOpenComposer = () => {
    openComposer();
  };

  const deleteAuthor = (type: string, authorId: string) => {
    let newUserIDs = post.user_ids;
    let newGuestAuthorIDs = post.guest_author_ids;

    if (type === 'user') {
      newUserIDs = newUserIDs.filter((id) => id !== authorId);
    }

    if (type === 'guest') {
      newGuestAuthorIDs = newGuestAuthorIDs.filter((id) => id !== authorId);
    }

    updatePost({ ...post, user_ids: newUserIDs, guest_author_ids: newGuestAuthorIDs });
  };

  return (
    <div className="flex h-[3rem] bg-gray-800 rounded-lg p-2">
      <Tippy
        offset={[0, 8]}
        placement="bottom-start"
        popperOptions={{
          modifiers: [{ name: 'flip' }],
        }}
        trigger="click"
        interactive
        content={
          <div className="absolute z-50">
            <AuthorsActionsMenu
              guestAuthors={guestAuthors}
              users={users}
              post={post}
              updatePost={updatePost}
              setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
              smallVersion
            />
          </div>
        }
      >
        <Button $variant="quaternary" $leftSlot={<Icon name="Person" className="mr-2" />} />
      </Tippy>
      <Button $variant="quaternary" $leftSlot={<Icon name="Comment" />} onClick={handleOpenComposer} />
      <Button
        $variant="quaternary"
        $leftSlot={<Icon name="Trash" />}
        onClick={() => {
          if (userId) {
            deleteAuthor('user', userId);
          } else if (guestAuthorId) {
            deleteAuthor('guest', guestAuthorId);
          }
        }}
      />
    </div>
  );
};

export default AuthorsTooltipMenu;
