import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cx from 'classnames';

import { ImageSelect } from '@/components/Form';
import Input from '@/components/Form/Input';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import useCreateGuest from '@/hooks/useCreateGuest';
import { Button, ButtonProps } from '@/ui/Button';

export interface Props {
  isOpen: boolean;
  isWorking?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  modalHeightClassName?: string;
  disabled?: boolean;
  buttonType?: ButtonProps['variant'];
}

const CreateGuestAuthorModal: FC<Props> = (props: Props) => {
  const { onSuccess } = props;
  const { mutateAsync: handleGuestAuthorSave } = useCreateGuest({ onSuccess });
  const { isOpen, isWorking, onClose, modalHeightClassName, disabled = false, buttonType = 'primary' } = props;
  const cancelRef = useRef(null);
  const [publicationId] = useCurrentPublicationState();
  const [profilePic, setProfilePic] = useState<string | File | any>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // get form data
    const formData = new FormData(e.currentTarget);

    // Only pass image if it's a binary or null.
    // If it's a string, it means the image didn't change
    // and we can leave it alone.
    if (typeof profilePic !== 'string') {
      formData.append('guest_author[profile_picture]', profilePic);
    }

    handleGuestAuthorSave({
      formData,
    });
  };

  const handleFileChange = (file: File) => {
    setProfilePic(file);
  };

  const handleFileClear = () => {
    setProfilePic(null);
  };

  useEffect(() => {
    if (isOpen) {
      setProfilePic(null);
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed overflow-y-auto inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-20" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <section className="modal z-50 fixed inset-0 w-screen overflow-auto">
            <div className="flex min-h-full justify-center p-4 items-center">
              <Dialog.Panel
                className="model-guts flex flex-col mx-auto bg-white rounded-lg text-left shadow-xl w-full md:max-w-xl"
                id="add-guest-author-modal-panel"
              >
                <form onSubmit={handleSubmit}>
                  <input type="hidden" value={publicationId} name="publication_id" />
                  <section className="p-6 overflow-y-auto">
                    <div className={cx('flex flex-col sm:flex-row gap-4', modalHeightClassName)}>
                      <div className="w-full flex flex-col">
                        <div className="w-full flex flex-row space-x-4">
                          <div className="flex flex-col">
                            <p className="mb-1 text-lg font-semibold">New Guest Author</p>
                          </div>
                        </div>
                        <div className="mt-10">
                          <Input
                            labelText="Full name"
                            type="text"
                            label="Full name*"
                            name="guest_author[name]"
                            placeholder="Name"
                            required
                          />
                          <div className="mt-6">
                            <Input
                              labelText="Twitter handle"
                              type="text"
                              label="Twitter handle"
                              name="guest_author[twitter_handle]"
                              placeholder="Enter Twitter handle"
                            />
                          </div>
                          <div className="w-40 mt-6">
                            <ImageSelect
                              name="guest_author[profile_picture]"
                              orientation="col"
                              labelText="Profile Picture"
                              dimensionSuggestion="800px x 800px recommended"
                              onFileSelect={handleFileChange}
                              onFileClear={handleFileClear}
                              file={profilePic}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="flex justify-between items-center bg-[#F9FAFB] rounded-b-lg py-4 px-6 mt-auto">
                    <div className="flex items-center gap-3 ml-auto">
                      <Button ref={cancelRef} type="button" variant="primary-inverse" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button variant={buttonType} type="submit" disabled={disabled} loading={isWorking}>
                        Save Guest Author
                      </Button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </section>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateGuestAuthorModal;
