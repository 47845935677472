import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import cx from "classnames";

import { AccordionCard } from "@/components/Accordion/variants/AccordionCard"
import Badge from "@/components/Badge";
import { PostEditorSteps } from "@/components/Layout/PostEditorLayout/constants";
import Tag from "@/components/Tag";
import { Typography } from "@/components/Typography";
import { useSplitTest } from "@/hooks";
import { Post, PostStatus } from "@/interfaces/post";
import { Button } from "@/ui/Button";
import { ButtonGroup } from "@/ui/Button/ButtonGroup/ButtonGroup";

interface Props {
  post: Post;
  setPostEditorStep: Dispatch<SetStateAction<PostEditorSteps>>;
}

const ABTestSettings = ({post, setPostEditorStep}: Props) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const splitTestObject = useSplitTest(post.id).data;
  const [splitTestDuration, setSplitTestDuration] = useState<number>(0);
  const [splitTestSamplePct, setSplitTestSamplePct] = useState<number>(0);
  const [splitTestPopulationSize, setSplitTestSize] = useState<number>(0);
  const [postSent, setPostSent] = useState(false);
  const [iconColor, setIconColor] = useState("text-action-secondary-600");

  useEffect(() => {
    if (splitTestObject) {
      setSplitTestDuration((splitTestObject.duration || 0) / 60);
      setSplitTestSamplePct(splitTestObject.sample_pct || 0);
      setSplitTestSize(splitTestObject.population_size || 0);
    }
  }, [splitTestObject]);

  useEffect(() => {
    if (post.status === PostStatus.ARCHIVED || post.status === PostStatus.PUBLISHED) {
      setIconColor("text-feedback-success-500");
      setPostSent(true);
    } else if (post.status === PostStatus.DRAFT) {
      setIconColor("text-action-secondary-600");
    } else {
      setIconColor("text-feedback-info-500");
    }
  }, [post.status]);


  return (
    <div className={cx("w-2/3 mx-auto", splitTestDuration === 0 ? 'hidden' : '')}>
      <AccordionCard
        titleGap="0"
        title={
          <span className="flex flex-row gap-3">
            <ArrowsRightLeftIcon className={cx("h-6 w-6 flex-grow", iconColor)} />
            <div className="flex flex-row gap-1">
              <Typography token="font-normal/text/base" colorWeight="500">
                A/B test for <Typography token="font-medium/text/base" colorWeight="900">{`${splitTestDuration} minutes`}</Typography>
              </Typography>
              {
                !accordionOpen && <Typography token="font-normal/text/base" colorWeight="500">
                  with <Typography token="font-medium/text/base" colorWeight="900">{splitTestSamplePct}% ({Math.round((splitTestSamplePct / 100) * (splitTestPopulationSize))}) subscribers</Typography>
                </Typography>
              }
            </div>
          </span>
        }
        subText=""
        topBorder={false}
        isControlledState
        isControlledStateOpen={accordionOpen}
        onClick={() => setAccordionOpen(!accordionOpen)}
        footer={
          <div className={
            cx(
              "flex px-3 py-6 justify-end items-center gap-3 self-stretch bg-surface-50",
              postSent ? 'hidden' : ''
            )
          }>
            <ButtonGroup>
              <Button onClick={() => setPostEditorStep(PostEditorSteps.EMAIL)} type="button" variant="primary-inverse" size="sm">Edit</Button>
            </ButtonGroup>
          </div>
        }
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-row gap-14">
            <div className="flex flex-col gap-2">
              <Typography token="font-medium/text/sm">
                Test Group
              </Typography>
              <Badge type="alert" size="md">
                {splitTestSamplePct}% ({Math.round((splitTestSamplePct / 100) * (splitTestPopulationSize))} subscribers)
              </Badge>
            </div>
            <div className="flex flex-col gap-2">
              <Typography token="font-medium/text/sm">
                Remaining Audience
              </Typography>
              <Tag label={`${100 - (splitTestSamplePct)}% (${Math.round(splitTestPopulationSize - (splitTestSamplePct / 100) * (splitTestPopulationSize))} subscribers)`} />
            </div>
          </div>
          <Typography token="font-normal/text/sm">
            {Math.round((splitTestSamplePct / 100) * (splitTestPopulationSize))} subscribers will receive one subject line variation. After {splitTestDuration} minutes {Math.round(splitTestPopulationSize - (splitTestSamplePct / 100) * (splitTestPopulationSize))} subscribers will receive the winning variation.
          </Typography>
        </div>
      </AccordionCard>
    </div>
  )
}

export default ABTestSettings;
