import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import toast from 'react-hot-toast';

import { useWebsiteContext } from '@/context/website-context';

import { cn } from '../../_utils/cn';

import { DndItemTypes, DragItemPage, DROP } from './types';
import { getHoverPageDirection, movePath } from './util';

type Props = PropsWithChildren<{
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  path: string[];
  id: string;
}>;

export function ContentTreeItemDndWrapper({ setIsOpen, path, id, children }: Props) {
  const { pagesRoutes, updatePagesRoutes } = useWebsiteContext();
  const [dropDirection, setdropDirection] = useState<DROP | null>(null);
  const isHoveringOver = useRef<NodeJS.Timeout | undefined>();
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag] = useDrag({
    type: DndItemTypes.PAGE,
    item: () =>
    ({
      path,
      id,
    } as DragItemPage),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: DndItemTypes.PAGE,
    drop: async (item: DragItemPage, monitor) => {
      if (!pagesRoutes) return;
      const direction = getHoverPageDirection(item.path, path, ref, monitor);
      if (!direction) return;

      try {
        // Can only move pages that are non default pages (custom pages under routes)
        const newRoute = movePath(pagesRoutes, item.path, path, direction);
        await updatePagesRoutes(newRoute);

        if (direction === DROP.INSIDE) {
          setIsOpen(true);
        }
      } catch (e) {
        toast.error('Failed to move page');
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    hover: (item: DragItemPage, monitor: DropTargetMonitor) => {
      const direction = getHoverPageDirection(item.path, path, ref, monitor);
      setdropDirection(direction);
    },
  });

  drag(drop(ref));

  // When dragging -> close this page if it has children
  useEffect(() => {
    if (isDragging) {
      setIsOpen(false);
    }
  }, [isDragging, setIsOpen]); // not adding onDrag to dependency because this will be called again when onDrag changed (which happens when it's re-rendered)

  // When long hovering over a page with sub-pages -> it should open the page
  useEffect(() => {
    if (isOver) {
      isHoveringOver.current = setTimeout(() => {
        if (isHoveringOver.current) {
          setIsOpen(true);
        }
      }, 1000);
    } else {
      if (isHoveringOver.current) {
        clearTimeout(isHoveringOver.current);
      }

      isHoveringOver.current = undefined;
    }
  }, [isOver, isHoveringOver, setIsOpen]);

  // when not hovering over, clear direction
  useEffect(() => {
    if (!isOver) {
      setdropDirection(null);
    }
  }, [isOver]);

  return (
    <div
      ref={ref}
      className={cn(
        'relative rounded-lg outline outline-1 flex items-center',
        dropDirection === DROP.INSIDE ? 'outline-wb-accent' : 'outline-transparent'
      )}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {children}
      {isOver && (dropDirection === DROP.TOP || dropDirection === DROP.BOTTOM) && (
        <div
          className={cn(
            'absolute left-[30px] w-[calc(100%_-_30px)] h-[2px] bg-wb-accent',
            dropDirection === DROP.TOP ? 'top-0 -translate-y-[2px]' : '',
            dropDirection === DROP.BOTTOM ? 'top-full -translate-y-[2px]' : ''
          )}
        />
      )}
    </div>
  );
}
