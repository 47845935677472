import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import moment from 'moment-mini';

import PostEditorLayout from '@/components/Layout/PostEditorLayout';
import { PostEditorSteps } from '@/components/Layout/PostEditorLayout/constants';
import PreviewModalV2 from '@/components/PreviewModalV2';
import { useCurrentPublication } from '@/hooks';
import { IData } from '@/interfaces/general';
import { Post } from '@/interfaces/post';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

import Audience from './Audience';
import Compose from './Compose';
import Email from './Email';
import { PostContext, PostContextContent } from './PostContext';
import Review from './Review';
import Web from './Web';

interface Props {
  postId: string;
  collaborationEnabled: boolean;
}

const Form = ({ postId, collaborationEnabled }: Props) => {
  const navigate = useNavigate();
  const [postEditorStep, setPostEditorStep] = useState<PostEditorSteps>(PostEditorSteps.COMPOSE);
  const [previewActive, setPreviewActive] = useState(false);
  const [previewOptions, setPreviewOptions] = useState<('Email' | 'Web')[]>([]);
  const post = useQuery<Post>(['post-v2', postEditorStep, previewActive], () => api.get(`/posts/${postId}`).then((res) => res.data), {
    cacheTime: 0,
  });
  const { data: currentPublication } = useCurrentPublication();

  const renderTab = (): ReactElement => {
    if (!post.data) return <div />;

    switch (postEditorStep) {
      case PostEditorSteps.AUDIENCE:
        return <Audience />;
      case PostEditorSteps.EMAIL:
        return <Email />;
      case PostEditorSteps.WEB:
        return <Web />;
      case PostEditorSteps.REVIEW:
        return <Review />;
      default:
        return <Compose post={post.data} collaborationEnabled={collaborationEnabled} />;
    }
  };

  const fetchPreview = useCallback(
    (platform: string, advancedParams: IData, onFetch: (html: string) => void) => {
      if (!currentPublication?.id) {
        return;
      }

      const params = {
        publication_id: currentPublication?.id,
        is_v2: true,
        platform,
        ...advancedParams,
      };
      api.get(`/posts/${postId}/preview`, { params }).then((resp) => onFetch(resp.data.html));
    },
    [postId, currentPublication]
  );

  const [formData, setFormData] = useState<Post | undefined>(post.data);

  useEffect(() => {
    setFormData(post.data);
  }, [post.data]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const save = useMemo(
    () =>
      debounce(async (data: Partial<Post>) => {
        abortControllerRef.current = new AbortController();

        return api
          .patch(
            `/posts/${post?.data?.id}`,
            { post: { ...data, id: post?.data?.id } },
            {
              signal: abortControllerRef.current.signal,
            }
          )
          .then((resp) => {
            if (resp.data.errors) {
              toast.error('There was an error saving the post');
            }
          })
          .catch((err) => {
            if (err.response?.data) {
              toast.error('There was an error saving the post');
            }
          })
          .finally(() => {
            abortControllerRef.current = null;
          });
      }, 450),
    [post?.data?.id]
  );

  const cancelSave = useCallback(() => {
    abortControllerRef.current?.abort();
  }, []);

  const onChange = useMemo(
    () =>
      (data: any) => {
        cancelSave();

        setFormData((prev) => {
          const newData = {
            ...prev,
            ...data,
          };

          save(data);

          return newData;
        });
      },
    [cancelSave, save]
  );

  const publishPost = useMutation(
    () => {
      return api
        .patch(`/posts/${post?.data?.id}/transition`, { status: 'confirmed' })
    }, {
      onSuccess: () => {
        if (post.data?.scheduled_at && moment(post.data?.scheduled_at).isAfter(moment())) {
          analytics.track('Scheduled Post');
          toast.success('Post successfully scheduled');
          navigate(`/posts/${post?.data?.id}?scheduled=true`);
        } else {
          analytics.track('Sent Post');
          toast.success('Post successfully published');
          navigate(`/posts/${post?.data?.id}?launch=true`);
        }
      }
    }
  )

  const providerValue = useMemo<PostContextContent>(() => {
    return {
      editor: null,
      setEditor: () => {},
      editorIsLoading: false,
      setEditorIsLoading: () => {},
      isSaving: false,
      setIsSaving: () => {},
      unsavedChanges: false,
      setUnsavedChanges: () => {},
      wordCount: 0,
      setWordCount: () => {},
      provider: undefined,
      setProvider: () => {},
      formData,
      users: [],
      setUsers: () => {},
      showSidebar: true,
      setShowSidebar: () => {},
      showSearchAndReplaceMenu: false,
      setShowSearchAndReplaceMenu: () => {},
      collaborationEnabled: false,
      showThreadsSidebar: false,
      setShowThreadsSidebar: () => {},
      loadingNodes: {},
      setLoadingNodes: () => {},
      activeThreadId: null,
      setActiveThreadId: () => {},
      selectThread: () => {},
      unselectThread: () => {},
      threads: null,
      createThread: () => {},
      highlightThread: () => {},
      removeHighlightThread: () => {},
      onChange,
      setPostEditorStep,
      publishPost,
    };
  }, [
      formData,
      onChange,
      setPostEditorStep,
      publishPost,
    ]
  );

  return (
    <PostContext.Provider value={providerValue}>
      <PostEditorLayout
        currentPostEditorStep={postEditorStep}
        setPostEditorStep={setPostEditorStep}
        setPreviewActive={setPreviewActive}
        setPreviewOptions={setPreviewOptions}
        isV2
      >
        <PreviewModalV2
          fetchPreview={fetchPreview}
          active={previewActive}
          tabs={previewOptions}
          publication={currentPublication}
          post={post?.data}
          onClose={() => setPreviewActive(false)}
          showSubscriberSelect
        />
        <div>{renderTab()}</div>
      </PostEditorLayout>
    </PostContext.Provider>
  );
};

export default Form;
