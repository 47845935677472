import { AccordionCard } from "@/components/Accordion/variants/AccordionCard";
import LargeItemRadioGroup, { LargeItemRadioOption } from "@/components/Form/LargeItemRadioGroup";
import { useCreatePostTarget, useDeletePostTarget } from "@/hooks/usePostTargets";
import { Post, PostSendStatus } from "@/interfaces/post";
import { PostTargetPlatform } from "@/interfaces/post_target";
import { Button,  } from "@/ui/Button";
import { ButtonGroup } from "@/ui/Button/ButtonGroup/ButtonGroup";

interface Props {
  emailPostSendTargets: any[];
  formData: Post;
  loadingEmailPostTargets: boolean;
  loadingWebPostTargets: boolean;
  openEmailSection: boolean;
  openPublishToSection: boolean;
  openWebSection: boolean;
  publishType: LargeItemRadioOption;
  refetchEmailPostTargets: () => void;
  refetchWebPostTargets: () => void;
  setOpenEmailSection: (open: boolean) => void;
  setOpenPublishToSection: (open: boolean) => void;
  setOpenWebSection: (open: boolean) => void;
  webPostSendTargets: any[];
}

const PublishTo = ({
  emailPostSendTargets,
  formData,
  loadingEmailPostTargets,
  loadingWebPostTargets,
  openEmailSection,
  openPublishToSection,
  openWebSection,
  publishType,
  refetchEmailPostTargets,
  refetchWebPostTargets,
  setOpenEmailSection,
  setOpenPublishToSection,
  setOpenWebSection,
  webPostSendTargets,
}: Props) => {
  const createEmailPostTarget = useCreatePostTarget(formData?.id || "", PostTargetPlatform.EMAIL);
  const createWebPostTarget = useCreatePostTarget(formData?.id || "", PostTargetPlatform.WEB);
  const deletePostTarget = useDeletePostTarget(formData?.id || "");

  const postSent = formData.send_status === PostSendStatus.SENT;

  // Mutations
  const deleteWebPostTargets = () => {
    webPostSendTargets.forEach((target) => {
      deletePostTarget.mutate(target.id, {
        onSuccess: () => refetchWebPostTargets(),
      })
    });
  }

  const deleteEmailPostTargets = () => {
    emailPostSendTargets.forEach((target) => {
      deletePostTarget.mutate(target.id, {
        onSuccess: () => refetchEmailPostTargets(),
      })
    });
  }

  const createDefaultEmailPostTarget = () => {
    createEmailPostTarget.mutate({
      params: {
        action: "include",
        receiver_type: "Publication",
        receiver_id: formData?.publication_id || "",
        tier_id: "free",
      }
    });
  }

  const createDefaultWebPostTarget = () => {
    createWebPostTarget.mutate({
      params: {
        action: "include",
        receiver_type: "Publication",
        receiver_id: formData?.publication_id || "",
        tier_id: "free",
      }
    });
  }

  // Handlers
  const handleUpdatePublishType = (option: LargeItemRadioOption) => {
    if (option.value === "email_and_web") {
      if ((emailPostSendTargets.length) === 0 && !loadingEmailPostTargets) {
        createDefaultEmailPostTarget();
      }
      if ((webPostSendTargets.length) === 0 && !loadingWebPostTargets) {
        createDefaultWebPostTarget();
      }
    } else if (option.value === "email") {
      if ((emailPostSendTargets.length) === 0 && !loadingEmailPostTargets) {
        createDefaultEmailPostTarget();
      }
      if (!postSent) {
        deleteWebPostTargets();
      }
    } else if (option.value === "web") {
      if ((webPostSendTargets.length) === 0 && !loadingWebPostTargets) {
        createDefaultWebPostTarget();
      }
      if (!postSent) {
        deleteEmailPostTargets();
      }
    }
  }

  const handlePublishToFooterButtonClick = () => {
    setOpenPublishToSection(false);
    switch (publishType.value) {
      case "email_and_web":
        setOpenEmailSection(true);
        break;
      case "email":
        setOpenEmailSection(true);
        break;
      case "web":
        setOpenWebSection(true);
        break;
      default:
        break;
    }
  }

  return (
    <AccordionCard
      title="Publish to"
      titleSize="base"
      titleWeight="medium"
      subText=""
      topBorder={false}
      defaultOpen
      isControlledState
      isControlledStateOpen={openPublishToSection}
      onClick={() => {
        if (!openPublishToSection) {
          setOpenPublishToSection(true);
          if (openEmailSection) setOpenEmailSection(false);
          if (openWebSection) setOpenWebSection(false);
        } else {
          setOpenPublishToSection(false);
        }
      }}
      footer={
        <div className="flex px-6 py-3 justify-end items-center gap-3 self-stretch bg-surface-50 rounded-b-lg">
          <ButtonGroup>
            <Button onClick={handlePublishToFooterButtonClick} type="button" variant="primary" size="sm">Next</Button>
          </ButtonGroup>
        </div>
      }
      marginTop="mt-0"
    >
      <LargeItemRadioGroup
        srOnlyLabel="Publish to"
        justifyItems="start"
        variant="secondary"
        options={[
          { name: "Email and web", value: "email_and_web", disabled: postSent && publishType.value === "web" },
          { name: "Email only", value: "email", disabled: postSent && publishType.value === "web" },
          { name: "Web only", value: "web", disabled: postSent && publishType.value !== "web" },
        ]}
        selected={publishType}
        onSelected={handleUpdatePublishType}
      />
    </AccordionCard>
  )
}

export default PublishTo;
