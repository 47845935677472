import { QueryParamConfig } from 'use-query-params';

import { Asset } from './asset';
import { Author } from './author';
import { CommentSectionStateType } from './comment';
import { DateFields, DateFilterTypes, DateOptionsProps } from './dates';
import { TiptapState } from './editor_versions';

export enum PostAudience {
  FREE = 'free',
  PREMIUM = 'premium',
  BOTH = 'both',
}

export enum PostPlatform {
  WEB = 'web',
  EMAIL = 'email',
  BOTH = 'both',
}

export enum PostStatus {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export enum PostSendStatus {
  WAITING = 'waiting',
  SENDING = 'sending',
  SENT = 'sent',
  TESTING = 'testing',
}

export enum PostSocialShare {
  TOP = 'top',
  NONE = 'none',
}

export enum PostBulkActions {
  NONE = '',
  ARCHIVE = 'archive',
  DELETE = 'delete',
}

export type DeviceType = 'mobile' | 'desktop';

export type GroupBy = 'link_id';

export interface PostStats {
  all_time_click_rate: number;
  all_time_open_rate: number;
  click_rate: number;
  click_rate_by_total_delivered: number;
  click_rate_by_total_opened: number;
  error_rate: number;
  open_rate: number;
  total_delivered: number;
  total_email_clicked: number;
  total_errored: number;
  total_opened: number;
  total_sent: number;
  total_spam_reported: number;
  total_unique_email_clicked: number;
  total_unique_opened: number;
  total_unique_web_clicked: number;
  total_unsubscribes: number;
  total_web_clicked: number;
  total_web_viewed: number;
}

export interface AdStats {
  click_rate: number;
}

export interface BoostStats {
  click_rate: number;
}

export type PostWebStats = Pick<PostStats, 'total_unique_web_clicked' | 'total_web_clicked' | 'total_web_viewed'> & {
  total_upgrades: number;
};
export type PostEmailClickStats = {
  total_unique_verified_clicked: number;
  total_verified_clicked: number;
};
export type PostSendAndOpenStats = Pick<
  PostStats,
  | 'total_sent'
  | 'total_delivered'
  | 'total_opened'
  | 'total_unique_opened'
  | 'total_errored'
  | 'total_spam_reported'
  | 'total_unsubscribes'
>;

export interface PostClick {
  email_click_through_rate: number;
  id: string;
  total_clicked: number;
  total_email_clicked: number;
  total_unique_email_clicked: number;
  total_unique_web_clicked: number;
  total_web_clicked: number;
  url: string;
  link_id?: string;
  url_offset_index?: number;
}

export interface ClickMapPostClick extends PostClick {
  link_id: string;
  url_offset_index: number;
}

export type PostClickStats = Pick<
  PostClick,
  | 'total_clicked'
  | 'total_email_clicked'
  | 'total_unique_email_clicked'
  | 'total_unique_web_clicked'
  | 'total_web_clicked'
  | 'email_click_through_rate'
>;

export interface PostStatsOverview {
  click_rate?: number;
  open_rate?: number;
  total_sent?: number;
  total_upgrades?: number;
  total_web_viewed?: number;
  total_unique_web_clicked?: number;
}

export interface PostOpens {
  opens: { [key: string]: number } | null;
}

export interface PostEngagement {
  email: string;
  subscriberId: string;
  totalClicked: number;
  totalOpened: number;
  unsubscribedAt: Date;
}

interface LockedFields {
  [key: string]: boolean;
}

export interface PostAudienceOption {
  name: PostAudience;
  size: number;
}

export interface Post {
  archivable: boolean;
  audience_options: PostAudienceOption[];
  audience: PostAudience;
  authors: Author[];
  color_palette: string[];
  comments_state: CommentSectionStateType;
  content_tag_ids: string[];
  content_tag_options: string[][];
  content_tags_enabled: boolean;
  custom_live_url?: string;
  custom_link_tracking_enabled: boolean;
  deletable: boolean;
  draft_url: string;
  display_byline_in_email: boolean;
  display_subtitle_in_email: boolean;
  display_thumbnail_on_web: boolean;
  display_title_in_email: boolean;
  editing_locked: boolean;
  editing_locked_reason?: string;
  email_capture_type_override_options: string[];
  email_capture_type_override: string | null;
  email_capture_type: string;
  email_header_social_share: boolean;
  email_header_engagement_buttons: boolean;
  email_preview_text?: string;
  email_sender_display_address: string;
  email_subject_line?: string;
  guest_author_ids: string[];
  has_ydoc: boolean;
  hide_from_feed: boolean;
  id: string;
  locked_fields: LockedFields;
  meta_default_description: string | null;
  meta_default_title: string | null;
  meta_og_description: string | null;
  meta_og_title: string | null;
  meta_twitter_description: string | null;
  meta_twitter_title: string | null;
  override_scheduled_at: string;
  paywall_break_price_id?: string;
  platform: PostPlatform;
  post_poll_ids: string[];
  publication_id: string;
  scheduled_at: string;
  send_status: PostSendStatus;
  slug: string;
  social_share_options: string[];
  social_share: string;
  split_test: boolean;
  status: PostStatus;
  thumbnail: Asset | null;
  thumbnail_id: string | null;
  tiptap_state: TiptapState;
  updated_at: string;
  url: string;
  user_ids: string[];
  web_subtitle?: string;
  web_title: string;
}

export interface PostErrors {
  [key: string]: string;
}

export interface PostWarnings {
  [key: string]: any;
}

/**
 * To keep the index view speedy, we only send back the fields we need.
 */
export interface PostPreview {
  archivable: boolean;
  audience: string;
  authors: Author[];
  deletable: boolean;
  guest_author_ids: string[];
  hide_from_feed: boolean;
  id: string;
  override_scheduled_at: string;
  platform: string;
  send_status: PostSendStatus;
  stats_overview: PostStatsOverview;
  image_url: string;
  status: PostStatus;
  subtitle: string;
  title: string;
  url: string;
}

/**
 * To keep post updates speedy, we keep elements that render the HTML in the
 * post information.
 */
export interface PostInformation {
  warnings: PostWarnings;
  word_count: number;
}

export type PostFormQueryParams = {
  advancedEnabled: QueryParamConfig<boolean>;
  afterDate: QueryParamConfig<Date>;
  audience: QueryParamConfig<(string | null)[]>;
  authorIds: QueryParamConfig<(string | null)[]>;
  beforeDate: QueryParamConfig<Date>;
  contentTags: QueryParamConfig<(string | null)[]>;
  dateField: QueryParamConfig<DateFields>;
  dateFilterType: QueryParamConfig<DateFilterTypes>;
  datesEnabled: QueryParamConfig<boolean>;
  endDate: QueryParamConfig<Date>;
  platforms: QueryParamConfig<(string | null)[]>;
  startDate: QueryParamConfig<Date>;
};

export type PartialPost = {
  id: string;
  web_title: string;
};

export enum OptionTypes {
  AUDIENCE = 'audience',
  AUTHOR_IDS = 'authorIds',
  CONTENT_TAGS = 'contentTags',
  PLATFORMS = 'platforms',
}

export type BoolOptionsProps = {
  [OptionTypes.AUTHOR_IDS]: (string | null)[];
  [OptionTypes.AUDIENCE]: (string | null)[];
  [OptionTypes.CONTENT_TAGS]: (string | null)[];
  [OptionTypes.PLATFORMS]: (string | null)[];
};

export type CheckboxOptionsProps = {
  options: BoolOptionsProps;
  authors: string[][];
  contentTags: string[][];
  handleOptionsChange: (value: object) => void;
};

export interface PostAdvancedSearchForm {
  showAdvanced: boolean;
  dates: DateOptionsProps;
  bools: BoolOptionsProps;
}

export enum PostScheduleOptions {
  SCHEDULE = 'schedule',
  PUBLISH_NOW = 'publish-now',
}

export type PostNodeContent = {
  advertisement_opportunity: AdvertisementOpportunityNode[];
  boost: BoostNode[];
};

export type AdvertisementOpportunityNode = {
  id: string;
  in_use: boolean;
};

export type BoostNode = {
  id: string;
  send_by: Date;
};

export type ShowingPosts = {
  id: string;
  audience: string;
  platform: string;
};

export type TextToSpeechConfig = {
  enabled: boolean;
  voice_id: string;
  model_id: string;
};

export type ElevenlabsModel = {
  model_id: string;
  name: string;
  description: string;
  can_do_text_to_speech: boolean;
};

export type ElevenlabsVoice = {
  voice_id: string;
  name: string;
  preview_url: string;
};
