import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ImageSelect, Input, TypeaheadMultiSelect } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { useCurrentPublication, useOnboarding } from '@/hooks';
import useOptions from '@/hooks/useOptions';
import { Option } from '@/interfaces/general';
import { OnboardingTypes } from '@/interfaces/onboarding';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

import { Layout, LeftPane, RightPane } from '../_components/Layout';
import OnboardingStepForm from '../_components/OnboardingStepForm';

const normalizedTags = (tags: string[]) => {
  const tagsIds: string[] = [];
  const tagsNames: string[] = [];

  tags.forEach((tag) => {
    const [id, name] = tag.split(':');
    tagsIds.push(id);
    tagsNames.push(name);
  });

  return {
    tagsIds,
    tagsNames,
  };
};

interface IData {
  [key: string]: any;
}

const OnboardingPublicationDetails = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [image, setImage] = useState<IData>();
  const { data: currentPublication } = useCurrentPublication();
  const { data: onboarding } = useOnboarding(currentPublication?.onboarding_id);
  const isMigrating = onboarding?.onboarding_type === OnboardingTypes.MIGRATING;

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [subdomainError, setSubdomainError] = useState('');
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  const [chosenTagsError, setChosenTagsError] = useState('');
  const [tagSearchQuery, setTagSearchQuery] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const isThreeSelected = chosenTags.length === 3;
  const isSubmitDisabled = nameError || subdomainError || chosenTagsError;

  useEffect(() => {
    if (currentPublication) {
      setName(currentPublication.name);
      setSubdomain(currentPublication.subdomain || '');
      const imageUrl =
        currentPublication?.logo?.url && currentPublication?.logo?.url.includes('defaults/logo.png')
          ? undefined
          : currentPublication?.logo?.url;
      setImage({ image: imageUrl });
      setChosenTags(
        currentPublication?.tags.map((tag: any) => {
          const capitalizedTag = tag.name
            .split(' ')
            .map((word: string) => {
              return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
          return `${tag.id}:${capitalizedTag}`;
        })
      );
    }
  }, [currentPublication]);

  const { tagsIds, tagsNames } = normalizedTags(chosenTags);

  const tags = useOptions('tags');
  const { data } = tags;
  const loadedOptions = data?.options;

  const memoizedOptions = useMemo(() => {
    if (loadedOptions?.length > 0) {
      return loadedOptions.map((option: [number, string]): Option => {
        const [id, value] = option;
        return {
          label: value,
          value: `${id}:${value}`,
        };
      });
    }

    return null;
  }, [loadedOptions]);

  const filtererdOptions = useMemo(() => {
    if (memoizedOptions && tagSearchQuery) {
      return memoizedOptions.filter((option: any) =>
        option.label.toLowerCase().includes(tagSearchQuery.toLowerCase().trim())
      );
    }

    return memoizedOptions;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagSearchQuery, memoizedOptions]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Form Validations
    if (!name) {
      setNameError('This field is required.');
      return;
    }

    if (!subdomain) {
      setSubdomainError('This field is required.');
      return;
    }

    const formData = new FormData();

    if (typeof image?.image !== 'string') {
      formData.append('publication[logo]', image?.image);
    }

    formData.append('publication_id', currentPublication?.id || '');
    formData.append('publication[name]', name);

    tagsIds.forEach((tagId) => {
      formData.append('publication[tag_ids][]', tagId);
    });

    setIsRequesting(true);
    api
      .patch(`/publications/${currentPublication?.id}`, formData)
      .then(() => {
        analytics.identify(currentUser?.id);
        analytics.track('Set Publication Details', {
          skippedPubTags: false,
          tags: tagsIds,
          email: currentUser?.email,
        });
        analytics.group(currentPublication?.id, {
          name,
          avatar: `https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,format=auto,onerror=redirect,quality=80${image?.image}`,
          object_type_id: 1,
        });
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        if (subdomain !== currentPublication?.subdomain) {
          api
            .patch(`/publications/${currentPublication?.id}/subdomain`, {
              resource_locator: {
                subdomain,
              },
            })
            .then((resp) => {
              analytics.track('Set Publication Domain', {
                skippedPubDomain: false,
                publicationUrl: resp.data.url,
                email: currentUser?.email,
              });
              navigate('/onboarding/how_did_you_hear_about_us');
            })
            .catch((errPayload) => {
              const error = errPayload?.response?.data?.error || 'Something went wrong';
              setSubdomainError(error);
            })
            .finally(() => {
              setIsRequesting(false);
            });
        } else {
          setIsRequesting(false);
          navigate('/onboarding/how_did_you_hear_about_us');
        }
      });
  };

  const handleFileChange = (file: File) => {
    setImage({ image: file });
  };

  const handleFileClear = () => {
    setImage(undefined);
  };

  // eslint-disable-next-line
  const handleDeselect = (name: string, value: string) => {
    const newChosenTags = chosenTags.filter((item) => !item.includes(value));
    setChosenTags(newChosenTags);
  };

  return (
    <>
      <Helmet>
        <title>Publication Details - beehiiv</title>
      </Helmet>

      <Layout>
        <LeftPane>
          <OnboardingStepForm
            buttonsClassName="pb-8"
            title="Now for our favorite part! 🤩"
            subtitle="Let&#39;s create your publication."
            onBack={() => {
              if (isMigrating) {
                return navigate('/onboarding/platform_migration');
              }

              return navigate('/onboarding/personas');
            }}
            onSubmit={handleSubmit}
            onSkip={() => navigate('/onboarding/how_did_you_hear_about_us')}
            isProcessing={isRequesting}
            isSubmitDisabled={Boolean(isSubmitDisabled)}
          >
            <div className="flex space-y-4 lg:space-y-0 lg:space-x-6 space-x-0 lg:flex-row flex-col">
              <div className="w-full lg:w-1/3">
                <Typography token="font-medium/text/sm" colorWeight="900">
                  Newsletter Logo
                </Typography>
                <ImageSelect
                  name="image"
                  labelText="Logo"
                  helperText="800 x 800 recommended"
                  onFileSelect={handleFileChange}
                  onFileClear={handleFileClear}
                  file={image?.image}
                  infoBubble
                />
              </div>

              <div className="flex flex-col space-y-4 w-full lg:w-2/3">
                <Input
                  className="w-full"
                  labelText="Newsletter Name"
                  variant="primary"
                  name="name"
                  placeholderText="John's Newsletter"
                  helperText="This name will be displayed on your landing page."
                  value={name}
                  onChange={(e) => {
                    setNameError('');
                    setName(e.target.value);
                  }}
                  autoFocus
                  errorText={nameError}
                />
                <div className="flex space-x-1 items-center">
                  <Input
                    className="w-full"
                    labelText="Subdomain Name"
                    variant="primary"
                    name="subdomain"
                    placeholderText="John's Newsletter"
                    helperText="This is your publication's live URL."
                    value={subdomain}
                    onChange={(e) => {
                      setSubdomainError('');
                      const normalizedSubdomain = e.target.value.split(' ').join('-');
                      setSubdomain(normalizedSubdomain);
                    }}
                    autoFocus
                    errorText={subdomainError}
                  />
                  <Typography size="sm" weight="semibold">
                    .beehiiv.com
                  </Typography>
                </div>
                {memoizedOptions && (
                  <TypeaheadMultiSelect
                    emptyLabel="No tag(s) found"
                    labelText="What's your newsletter about?"
                    name="publication_tags"
                    disabled={isThreeSelected}
                    onClear={() => setTagSearchQuery('')}
                    onDeselect={handleDeselect}
                    badgeColor="tertiary"
                    onSelect={(tagName, tagValue) => {
                      setChosenTagsError('');
                      setChosenTags([...chosenTags, tagValue]);
                    }}
                    onDeselectAll={() => {}}
                    showClearAll={false}
                    onSearchQueryChange={setTagSearchQuery}
                    onSearch={async () => {
                      return new Promise((resolve) => {
                        resolve(filtererdOptions);
                      });
                    }}
                    helperText="Add tags to personalize your publication in the beehiiv network!"
                    placeholderText="Add up to 3 content tags"
                    selectedPreview={tagsNames}
                    values={chosenTags}
                    errorText={chosenTagsError}
                  />
                )}
              </div>
            </div>
          </OnboardingStepForm>
        </LeftPane>
        <RightPane />
      </Layout>
    </>
  );
};

export default OnboardingPublicationDetails;
