import { useEffect, useRef, useState } from 'react';
import { Image, X } from '@phosphor-icons/react';

import InputWrapper from './InputWrapper';

interface Props {
  value: string | null;
  onSave: (file: string | File | any) => void;
  disabled?: boolean;
  width: number;
  height: number | string;
  onRemove: Function;
  labelText?: string;
  helperText?: string | React.ReactNode;
}

const ImageInput = ({ labelText, helperText, value, onSave, disabled = false, width, height, onRemove }: Props) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const [imageFile, setImageFile] = useState<string | File | any>(null);
  const [imageURL, setImageURL] = useState<string | null>(value || null);

  const handleImageClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.files && e.target.files.length > 0) {
      setImageFile(e.target.files[0]);

      if (onSave) {
        onSave(e.target.files[0]);
      }
    }
  };

  const handleRemove = () => {
    onRemove();
    setImageFile(null);
    setImageURL(value);
    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };

  useEffect(() => {
    if (imageFile) {
      setImageURL(URL.createObjectURL(imageFile));
    } else {
      setImageURL(value);
    }
  }, [imageFile, value]);

  return (
    <InputWrapper labelText={labelText} helperText={helperText}>
      <input
        ref={fileInput}
        type="file"
        accept="image/*"
        multiple={false}
        onChange={handleFileInput}
        className="hidden"
        disabled={disabled}
      />

      <div className="relative mt-2" style={{ width, height }}>
        {imageURL && (
          <button
            className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-wb-secondary rounded-full p-1 z-10"
            onClick={handleRemove}
            type="button"
          >
            <X size={16} className="text-wb-secondary" weight="bold" />
          </button>
        )}
        <button
          className="w-full h-full relative bg-wb-tertiary rounded-md flex items-center justify-center border border-wb-primary"
          onClick={handleImageClick}
          type="button"
        >
          <Image size={24} className="text-wb-secondary" />
          {imageURL && (
            <img
              src={imageURL || ''}
              alt={labelText || ''}
              className="absolute top-0 right-0 w-full h-full object-cover rounded-md"
            />
          )}
          {imageURL && (
            <div className="absolute top-0 right-0 w-full h-full bg-wb-overlay flex items-center justify-center opacity-0 hover:opacity-100 transition-all duration-150">
              <Image size={24} className="text-white" weight="fill" />
            </div>
          )}
        </button>
      </div>
    </InputWrapper>
  );
};

export { ImageInput };
