export function dateToAgo(dateString: string): string {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];

  const interval = intervals.find(itv => {
    const count = Math.floor(diffInSeconds / itv.seconds);
    return count >= 1;
  });

  if (interval) {
    const count = Math.floor(diffInSeconds / interval.seconds);
    return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
  }

  return 'just now';
}
