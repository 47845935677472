import { Dispatch, SetStateAction, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ArrowLeftIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import PreviewDropdown from '@/components/PreviewModalV2/PreviewDropdown';
import { Post, PostStatus } from '@/interfaces/post';
import { usePostContext } from '@/pages/Post/Edit/v2/PostContext';
import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';

import { TOP_BAR_HEIGHT_CLASS_NAME } from '../AppLayout/TopBar/constants';

import { PostEditorSteps } from './constants';

interface Props {
  currentPostEditorStep: PostEditorSteps;
  setPostEditorStep: Dispatch<SetStateAction<PostEditorSteps>>;
  setShowUnpublishModal: Dispatch<SetStateAction<boolean>>;
  setPreviewActive: Dispatch<SetStateAction<boolean>>;
  postStatus?: PostStatus;
  stepsOrder: PostEditorSteps[];
  post?: Post;
}

const Line = () => (
  <div className='w-5 h-[1px] bg-surface-200 my-auto'/>
)

const TopBar = ({ currentPostEditorStep,
  setPostEditorStep,
  setShowUnpublishModal,
  setPreviewActive,
  postStatus, stepsOrder,
  post
}: Props) => {
  const { publishPost } = usePostContext();
  const [showPreviewDropdown, setShowPreviewDropdown] = useState(false);

  const isPastFirstStep = stepsOrder.indexOf(currentPostEditorStep) > 0;
  const isBeforeLastStep = stepsOrder.indexOf(currentPostEditorStep) < stepsOrder.length - 1;
  const isLastStep = stepsOrder.indexOf(currentPostEditorStep) === stepsOrder.length - 1;

  const goToPreviousStep = () => {
    setPostEditorStep(stepsOrder[stepsOrder.indexOf(currentPostEditorStep) - 1]);
  }

  const goToNextStep = () => {
    setPostEditorStep(stepsOrder[stepsOrder.indexOf(currentPostEditorStep) + 1]);
  }

  const renderPublishButton = () => {
    if (postStatus === PostStatus.DRAFT) {
      return (
        <Button
          onClick={() => publishPost?.mutate()}
          size="sm"
          variant="primary"
          type="button"
          className="my-auto"
          disabled={!post?.scheduled_at}
        >
          Publish
        </Button>
      );
    }

    if (postStatus === PostStatus.SCHEDULED) {
      return (
        <Button
          onClick={() => setShowUnpublishModal(true)}
          size="sm"
          variant="primary-inverse"
          className="!text-feedback-danger-600 my-auto"
          type="button"
        >
          Unschedule
        </Button>
      );
    }

    if (postStatus === PostStatus.PUBLISHED || postStatus === PostStatus.ARCHIVED) {
      return null;
    }

    return null;
  }

  return (
    <div className={cx('screen:flex flex-col justify-between items-center print:hidden', TOP_BAR_HEIGHT_CLASS_NAME)}>
      <div className="w-full h-full flex flex-row justify-center items-between">
        <div className="w-full py-2 pr-6 flex flex-row space-x-4 justify-between items-end bg-white">
          <div className='flex flex-row gap-2'>
            <div>
              <Button
                onClick={() => setPostEditorStep(PostEditorSteps.COMPOSE)}
                size="sm"
                variant="flush"
                type="button"
                className={cx("my-auto", currentPostEditorStep === PostEditorSteps.COMPOSE ? 'text-primary' : 'text-surface-300')}
              >
                Compose
              </Button>
            </div>
            <div className='flex flex-col justify-center'>
              <Line />
            </div>
            <div>
              <Button
                onClick={() => setPostEditorStep(PostEditorSteps.AUDIENCE)}
                size="sm"
                variant="flush"
                type="button"
                className={cx("my-auto", currentPostEditorStep === PostEditorSteps.AUDIENCE ? 'text-primary' : 'text-surface-300')}
              >
                Audience
              </Button>
            </div>
            <div className={cx('flex flex-col justify-center', stepsOrder.some((step: PostEditorSteps) => step === PostEditorSteps.EMAIL) ? '' : 'hidden')}>
              <Line />
            </div>
            <div className={stepsOrder.some((step: PostEditorSteps) => step === PostEditorSteps.EMAIL) ? '' : 'hidden'}>
              <Button
                onClick={() => setPostEditorStep(PostEditorSteps.EMAIL)}
                size="sm"
                variant="flush"
                type="button"
                className={cx("my-auto", currentPostEditorStep === PostEditorSteps.EMAIL ? 'text-primary' : 'text-surface-300')}
              >
                Email
              </Button>
            </div>
            <div className={cx('flex flex-col justify-center', stepsOrder.some((step: PostEditorSteps) => step === PostEditorSteps.WEB) ? '' : 'hidden')}>
              <Line />
            </div>
            <div className={stepsOrder.some((step: PostEditorSteps) => step === PostEditorSteps.WEB) ? '' : 'hidden'}>
              <Button
                onClick={() => setPostEditorStep(PostEditorSteps.WEB)}
                size="sm"
                variant="flush"
                type="button"
                className={cx("my-auto", currentPostEditorStep === PostEditorSteps.WEB ? 'text-primary' : 'text-surface-300')}
              >
                Web
              </Button>
            </div>
            <div className='flex flex-col justify-center'>
              <Line />
            </div>
            <div>
              <Button
                onClick={() => setPostEditorStep(PostEditorSteps.REVIEW)}
                size="sm"
                variant="flush"
                type="button"
                className={cx("my-auto", currentPostEditorStep === PostEditorSteps.REVIEW ? 'text-primary' : 'text-surface-300')}
              >
                Review
              </Button>
            </div>
          </div>
          <div className='flex gap-3 justify-end'>
            <div className="flex flex-row relative">
              <ButtonGroup size="sm">
                <Button
                  type="button"
                  variant='primary-inverse'
                  Icon={ArrowsPointingOutIcon}
                  onClick={() => setPreviewActive(true)}
                >
                  Preview
                </Button>
                <Button
                  type="button"
                  variant='primary-inverse'
                  onClick={() => setShowPreviewDropdown(!showPreviewDropdown)}
                >
                  <ChevronDownIcon className={`w-4 h-4 transition ease-out transform duration-100 ${showPreviewDropdown && 'rotate-180'}`} />
                </Button>
              </ButtonGroup>
              <PreviewDropdown post={post} visible={showPreviewDropdown} onClose={() => setShowPreviewDropdown(false)} />
            </div>
            {
              isPastFirstStep && (
                <Button
                  onClick={goToPreviousStep}
                  size="sm"
                  variant="flush"
                  type="button"
                  className="my-auto"
                  Icon={ArrowLeftIcon}
                >
                  Back
                </Button>
              )
            }
            {
              isBeforeLastStep && (
                <Button
                  onClick={goToNextStep}
                  size="sm"
                  variant="primary"
                  type="button"
                  className="my-auto"
                >
                  Continue
                </Button>
              )
            }
            {
              isLastStep && renderPublishButton()
            }
          </div>
        </div>
      </div>
      <hr className="w-full" />
    </div>
  );
};

export default TopBar;
