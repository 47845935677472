import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';
import cx from 'classnames';
import styled from 'styled-components';

import { PendingOpportunitiesBanner } from '@/components/PendingOpportunitiesBanner';
import { WalkthroughPortal } from '@/components/Walkthrough';
import { useWalkthroughContext, WalkthroughProvider } from '@/context/walkthrough-context';
import { useOrganization, useUser } from '@/hooks';
import useAppLayout from '@/hooks/useAppLayout';
import useFreeTrial from '@/hooks/useFreeTrial';
import { Organization } from '@/interfaces/organization';
import { Publication } from '@/interfaces/publication';
import { IUser, UserWithRoles } from '@/interfaces/user';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { useCurrentUser } from '../../../context/current-user-context';
import { useSettings } from '../../../context/settings-context';
import { usePublication } from '../../../hooks/usePublications';
import GlobalBanner from '../../GlobalBanner';
import MasqueradeBanner from '../../MasqueradeBanner';

import EmailConfirmationBanner from './Banners/EmailConfirmationBanner';
import SubscriberLimitBanner from './Banners/SubscriberLimitBanner';
import { BODY_HEIGHT_WITH_TOP_BAR } from './TopBar/constants';
import { APP_LAYOUT_BODY_CONTAINER_ID } from './constants';
import MainNav from './MainNav';
import TopBar from './TopBar';

const NavFiller: any = styled.div`
  width: ${({ largeNavOpen }: any) => (largeNavOpen ? '230px' : '60px')};
  min-width: ${({ largeNavOpen }: any) => (largeNavOpen ? '230px' : '60px')};
  height: 100vh;

  @media (max-width: 640px) {
    width: 0px;
    min-width: 0px;
    display: ${({ isSettingsOpen }: any) => (isSettingsOpen ? 'none' : 'block')};
  }

  @media print {
    display: none;
  }
`;

const LockedAccountView = () => (
  <div className="w-full flex justify-center items-center">
    <div className="block text-center">
      <p>
        This account has been locked due to a potential violation of our{' '}
        <a href="https://www.beehiiv.com/tou" className="text-primary-600 hover:underline">
          Terms of Use
        </a>
        .{' '}
      </p>
      <p>If you believe this is in error, please contact support.</p>
    </div>
  </div>
);

const MainNavWrapper = ({ isAdmin, largeNavOpen }: any) => {
  const { currentStep, navigationStep, handleNextStep, handlePreviousStep, handleFinishWalkthrough } =
    useWalkthroughContext();
  const isLastStep = navigationStep?.currentStep === 7;

  return (
    <WalkthroughPortal
      isActive={currentStep === navigationStep?.currentStep}
      title={navigationStep?.title || ''}
      description={navigationStep?.description || ''}
      arrowDirection="left"
      positionClassNameOverride={navigationStep?.positionClassNameOverride || ''}
      arrowPositionOverride={navigationStep?.arrowPositionOverride || ''}
      hasOverlay
      currentStep={navigationStep?.currentStep || 0}
      totalSteps={7}
      continueButton={{
        text: isLastStep ? 'Finish' : 'Next',
        onClick: () => handleNextStep(navigationStep?.nextPath),
      }}
      backButton={{
        text: 'Back',
        onClick: () => handlePreviousStep(navigationStep?.backPath),
      }}
      onClose={handleFinishWalkthrough}
    >
      <div className="z-20 print:hidden">
        <MainNav largeNavOpen={largeNavOpen} isAdmin={isAdmin} />
      </div>
    </WalkthroughPortal>
  );
};

const getMetaFields = (user: IUser | UserWithRoles, isTrialActive: boolean, currentPublication: Publication, org: Organization) => {
  return {
    user_id: user.id,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    channel: 'ada-in-app',
    hashed_email: user.ada_token,
    currently_in_trial: isTrialActive,
    has_premium_newsletter: currentPublication.is_premium_enabled,
    plan_name: currentPublication.plan_name,
    organization_id: currentPublication.organization_id,
    publication_id: currentPublication.id,
    publication_name: currentPublication.name,
    organization_name: org.name,
  };
};

const toggleAdaChatButton = (adaWidgetEnabled: boolean | undefined) => {
  if (adaWidgetEnabled === true) {
    document.querySelector('#nav-ada-chat-button')?.classList.add('!block');
  } else {
    document.querySelector('#nav-ada-chat-button')?.classList.remove('!block');
  }
};

const resetMetaFields = async (
  user: IUser | UserWithRoles,
  isTrialActive: boolean,
  currentPublication: Publication | undefined,
  org: Organization,
  adaWidgetEnabled: boolean | undefined
) => {
  try {
    if (user && currentPublication && org && adaWidgetEnabled) {
      await window.adaEmbed.setMetaFields(getMetaFields(user, isTrialActive, currentPublication, org));
      toggleAdaChatButton(adaWidgetEnabled);
    }
  } catch {
    // console.error('Error resetting Ada Embed:', error);
  }
};

const AppLayout = () => {
  const location = useLocation();
  const { isSettingsPages, hasTopPadding, hasSubLayout } = useAppLayout();

  const defaultState = localStorage.getItem('largeNavOpen');
  const [largeNavOpen, setLargeNavOpen] = useState<boolean>(defaultState === 'true');

  const [publicationId] = useCurrentPublicationState();
  const currentPublication = usePublication(publicationId)?.data;
  const { settings } = useSettings();
  const { currentUser } = useCurrentUser();
  const userQuery = useUser(currentUser?.id);
  const user = userQuery?.data;
  const { data: org } = useOrganization(currentPublication?.organization_id);
  const { isTrialActive } = useFreeTrial();

  const isNotifications = location.pathname.startsWith('/notifications');
  const isSysAdmin = settings?.system_admin;
  const isOrgOrPubAdmin = user?.roles.some((role) => role.name === 'admin');
  const isAdmin = isSysAdmin || isOrgOrPubAdmin;

  const defaultTitle = currentPublication ? `${currentPublication.name} - beehiiv` : 'beehiiv';

  const disableView = currentPublication?.is_sending_disabled && !isSettingsPages && !isSysAdmin;

  useEffect(() => {
    localStorage.setItem('largeNavOpen', largeNavOpen.toString());
  }, [largeNavOpen]);

  useEffect(() => {
    if (location.pathname.startsWith('/settings')) setLargeNavOpen(true);
  }, [location.pathname]);

  useEffect(() => {
    // This  useEffect gets call to often to be able to close the chat on pub switch so i think the best we can do is update the metaFields
    const startAdaEmbed = async () => {
      try {
        if (user && currentPublication && org && settings?.ada_widget) {
          await window.adaEmbed.start({
            handle: 'beehiiv',
            metaFields: getMetaFields(user, isTrialActive, currentPublication, org.organization),
          });
        }

        toggleAdaChatButton(settings?.ada_widget);
      } catch {
        resetMetaFields(user as UserWithRoles, isTrialActive, currentPublication, org.organization, settings?.ada_widget);
      }
    };

    startAdaEmbed();
  }, [user, isTrialActive, currentPublication, org, settings?.ada_widget]);

  return (
    <WalkthroughProvider>
      <Helmet defaultTitle={defaultTitle} titleTemplate={`%s - ${defaultTitle}`} />

      {currentPublication && <SubscriberLimitBanner organization_id={currentPublication.organization_id} />}
      <MasqueradeBanner org={org?.organization} />
      <EmailConfirmationBanner />
      <GlobalBanner />
      <PendingOpportunitiesBanner />

      {/* Main */}
      <div className={cx('h-full flex flex-col min-h-screen relative', isSettingsPages ? 'bg-white' : 'bg-gray-100')}>
        <MainNavWrapper largeNavOpen={largeNavOpen} isAdmin={isAdmin} />
        <div className="flex max-w-screen">
          <NavFiller largeNavOpen={largeNavOpen} isSettingsOpen={isSettingsPages} />

          {disableView ? (
            <LockedAccountView />
          ) : (
            <div
              id={APP_LAYOUT_BODY_CONTAINER_ID}
              className={cx(
                'w-full overflow-auto h-screen max-h-screen relative',
                 isSettingsPages || isNotifications ? 'bg-white' : 'bg-surface-50'
              )}
            >
              {!isSettingsPages && (
                <div className="sticky sm:block hidden top-0 z-50 bg-white">
                  <TopBar largeNavOpen={largeNavOpen} setLargeNavOpen={setLargeNavOpen} />
                </div>
              )}
              <div
                className={cx({
                  'py-6': hasTopPadding,
                  [BODY_HEIGHT_WITH_TOP_BAR]: hasSubLayout,
                })}
              >
                <Outlet />
              </div>
            </div>
          )}
        </div>
      </div>
    </WalkthroughProvider>
  );
};

export default AppLayout;
