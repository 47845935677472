import React from 'react';
import { GearSix, House } from '@phosphor-icons/react';

import { WebsiteProvider } from '@/context/website-context';

import { ContentTree } from './_components/ContentTree';
import { ContentTreeProvider } from './_components/ContentTree/context';
import { Layout } from './_components/Layout';
import { Main } from './_components/Main';
import { Breadcrumbs } from './_components/Main/Breadcrumbs';
import { PageCard } from './_components/Main/PageCard';
import { TopBar } from './_components/Main/TopBar';
import { NavMenuItem } from './_components/SideNav/NavMenuItem';
import { NavSection } from './_components/SideNav/NavSection';
import { NavSectionTitle } from './_components/SideNav/NavSectionTitle';
import useSetPages from './_hooks/useSetPages';

const WebsiteDashboardPage = () => {
  const { pages, pageRoutes, defaultRoutes } = useSetPages();

  return (
    <Layout
      showBackButton
      backPath="/"
      sidenavChildren={
        <>
          <NavSection>
            <NavSectionTitle title="Site Settings" />
            <NavMenuItem Icon={House} title="Home" to="/website_builder_v2" />
            <NavMenuItem Icon={GearSix} title="Site Settings" to="/website_builder_v2/settings" />
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Pages" />
            {pageRoutes && (
              <ContentTreeProvider isAllowDnD isShowOptions isShowAddPage pageLinkPrefix="/website_builder_v2/page">
                <ContentTree route={pageRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Default Pages" />
            {defaultRoutes && (
              <ContentTreeProvider
                isAllowDnD={false}
                isShowOptions
                isShowAddPage
                pageLinkPrefix="/website_builder_v2/page"
              >
                <ContentTree showHome={false} route={defaultRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
          </NavSection>
        </>
      }
    >
      <Main>
        <Breadcrumbs />
        <TopBar showDropdown />

        <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
          {pages?.map((page) => {
            return <PageCard key={page.id} page={page} />;
          })}
        </div>
      </Main>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <WebsiteDashboardPage />
  </WebsiteProvider>
);
