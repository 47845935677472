import { PropsWithChildren, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Eye, GearSix, SidebarSimple } from '@phosphor-icons/react';

import { DeviceGate } from '@/ui/DeviceGate';

import { PublishDropDown } from '../PublishDropDown';
import { SideNav } from '../SideNav';
import { Button } from '../UI/Button';

import { BackButton } from './BackButton';
import { LogoDropDown } from './LogoDropDown';
import { NavbarTitle } from './NavbarTitle';
import { TopNav } from './TopNav';

type Props = PropsWithChildren<{
  sidenavChildren?: React.ReactNode;
  showLogoDropDown?: boolean;
  showBackButton?: boolean;
  backPath?: string;
  isSidebarResizable?: boolean;
  leftChildren?: React.ReactNode;
  titleType?: 'publication_name' | 'page_name';
}>;

export const Layout = ({
  children,
  sidenavChildren,
  backPath,
  showLogoDropDown,
  showBackButton,
  isSidebarResizable,
  leftChildren,
  titleType = 'publication_name',
}: Props) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const navigate = useNavigate();

  return (
    <DeviceGate reason="The feature is currently not supported on mobile devices">
      <div className="h-screen w-screen flex flex-col">
        <TopNav>
          {/* center */}
          <div className="absolute top-0 left-0 right-0 bottom-0 w-full flex items-center justify-center">
            <NavbarTitle type={titleType} />
          </div>

          {/* left */}
          <div className="relative flex justify-start gap-6">
            {showBackButton && (
              <BackButton
                onClick={() => {
                  navigate(backPath || '/website_builder_v2');
                }}
              />
            )}
            <div className="flex items-center gap-1.5">
              {showLogoDropDown && <LogoDropDown />}
              {isSidebarResizable && (
                <Button variant="ghost" Icon={SidebarSimple} onClick={() => setIsSidebarCollapsed((prev) => !prev)} />
              )}
            </div>
            {leftChildren}
          </div>

          {/* right */}
          <div className="relative flex justify-end gap-2">
            <Link to="/website_builder_v2/settings">
              <Button variant="secondary" Icon={GearSix} iconWeight="fill" />
            </Link>
            <Button variant="secondary" Icon={Eye} iconWeight="fill" />
            <PublishDropDown />
          </div>
        </TopNav>

        <div className="bg-wb-primary flex-grow flex overflow-auto">
          <SideNav
            className={isSidebarResizable ? 'border-r border-wb-primary' : ''}
            isResizable={isSidebarResizable}
            isCollapsed={isSidebarCollapsed}
          >
            {sidenavChildren}
          </SideNav>
          <div className="w-full h-full overflow-y-auto p-7">{children}</div>
        </div>
      </div>
    </DeviceGate>
  );
};
