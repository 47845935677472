import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import ErrorBoundary from './components/ErrorBoundary';
import HumanTag from './components/HumanTag';
import { AppProvider } from './context/app-context';
import App from './App';

import './index.css';

declare global {
  interface Window {
    env: {
      [key: string]: string | undefined;
    };
    confirm: (message?: string) => boolean;
    profitwell: (command: string, args: any) => void;
    adaEmbed: {
      toggle: () => void;
      start: (fields: Record<string, any>) => void;
      setMetaFields: (fields: Record<string, any>) => void;
    };
    hbspt?: {
      forms: {
        create: (options: {
          region: string;
          portalId: string;
          formId: string;
          target: string;
          onFormReady?: (form: any) => void;
        }) => void;
      };
    };
  }
}

Sentry.init({
  dsn: window.env.REACT_APP_SENTRY_DSN || '',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.25,
});

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <AppProvider>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: 'z-toast',
            duration: 3000,
          }}
        />
        <HumanTag />
        <App />
      </AppProvider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
);
