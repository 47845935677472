import { useEffect, useMemo, useState } from "react";

import Banner from "@/components/Banner";
import { LargeItemRadioOption } from "@/components/Form/LargeItemRadioGroup";
import { Typography } from "@/components/Typography";
import { usePostDeliveryData } from "@/hooks/usePostDeliveryData";
import { usePostTargets } from "@/hooks/usePostTargets";
import { useTiers } from "@/hooks/useTiers";
import { PostTargetPlatform } from "@/interfaces/post_target";
import { Tier, TierStatus } from "@/interfaces/tier";

import { usePostContext } from "../PostContext";

import EmailAudience from "./EmailAudience";
import PublishTo from "./PublishTo";
import SummaryCard from "./SummaryCard";
import WebAudience from "./WebAudience";

const Audience = () => {
  const [publishType, setPublishType] = useState<LargeItemRadioOption>({ name: "Email and web", value: "email_and_web" });
  const [paywallBreakPriceId, setPaywallBreakPriceId] = useState<string>("");
  const [showEmailSection, setShowEmailSection] = useState(false);
  const [showWebSection, setShowWebSection] = useState(false);
  const [openEmailSection, setOpenEmailSection] = useState(false);
  const [openWebSection, setOpenWebSection] = useState(false);
  const [openPublishToSection, setOpenPublishToSection] = useState(true);
  const [paidTiers, setPaidTiers] = useState<Tier[]>([]);
  const [displayAudioNewsletterBanner, setDisplayAudioNewsletterBanner] = useState(false);

  // Hooks
  const { formData, onChange, setPostEditorStep } = usePostContext();
  const { data: postDeliveryData } = usePostDeliveryData(formData?.id || "");
  const { data: webPostTargets, refetch: refetchWebPostTargets, isLoading: loadingWebPostTargets } = usePostTargets(formData?.id || "", PostTargetPlatform.WEB);
  const { data: emailPostTargets, refetch: refetchEmailPostTargets, isLoading: loadingEmailPostTargets } = usePostTargets(formData?.id || "", PostTargetPlatform.EMAIL);
  const { data: tiers = [] } = useTiers();

  // Memoized values
  const emailPostSendTargets = useMemo(() => emailPostTargets?.post_targets || [], [emailPostTargets]);
  const webPostSendTargets = useMemo(() => webPostTargets?.post_targets || [], [webPostTargets]);

  const tierOptions = useMemo(() => (
    paidTiers.flatMap((tier) =>
      tier.prices
        .filter((price) => price.enabled || formData?.paywall_break_price_id === price.id)
        .map((price) => ({
          label: `${tier.name}/${price.denominator}`,
          value: price.id,
        }))
    )
  ), [paidTiers, formData]);

  // Effects
  useEffect(() => {
    setPaywallBreakPriceId(formData?.paywall_break_price_id || "");
  }, [formData]);

  useEffect(() => {
    if (postDeliveryData) {
      if (postDeliveryData.for_email && postDeliveryData.for_web) {
        setShowEmailSection(true);
        setShowWebSection(true);
        setPublishType({ name: "Email and web", value: "email_and_web" });
      } else if (postDeliveryData.for_email) {
        setShowEmailSection(true);
        setShowWebSection(false);
        if (localStorage.getItem(`displayAudioNewsletterBanner-${formData?.id}`) !== "false") {
          setDisplayAudioNewsletterBanner(true);
        }
        setPublishType({ name: "Email only", value: "email" });
      } else if (postDeliveryData.for_web) {
        setShowWebSection(true);
        setShowEmailSection(false);
        setPublishType({ name: "Web only", value: "web" });
      }
    }
  }, [postDeliveryData]);

  useEffect(() => {
    const activeTiers = tiers.filter((tier) => tier.status === TierStatus.ACTIVE)
    if (activeTiers.length > 0) {
      setPaidTiers(activeTiers);
    }
  }, [tiers]);

  useEffect(() => {
    if (paywallBreakPriceId === "") {
      const tierIds = webPostSendTargets.map((pst) => pst.tier_id);
      const priceIds = tiers.filter((t) => tierIds.includes(t.id)).flatMap((t) => t.prices.map((p) => p.id));
      const initialPrice = priceIds[0];
      if (initialPrice) {
        onChange({ paywall_break_price_id: initialPrice });
      }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paywallBreakPriceId]);


  if (!formData) {
    return null;
  }

  const handleClickLearnMore = () => {
    if (window.env.REACT_APP_AUDIO_NEWSLETTER_LEARN_MORE_URL) {
      window.open(window.env.REACT_APP_AUDIO_NEWSLETTER_LEARN_MORE_URL, '_blank');
    }
  }

  const handleDismissAudioNewsletterBanner = () => {
    setDisplayAudioNewsletterBanner(false);
    localStorage.setItem(`displayAudioNewsletterBanner-${formData?.id}`, "false");
  }

  return (
    <div className="px-2 md:px-20 py-10 flex flex-row gap-6 justify-items-stretch">
      <div className="flex flex-col gap-6 md:w-2/3">
        {
          displayAudioNewsletterBanner &&
          <Banner
            variant="information"
            title="Audio newsletter is only available when you publish to web"
            bodyText={
              <div className="flex flex-col gap-2.5">
                You can continue without the audio feature or publish to web to enable it.
                <div className="flex flex-row gap-4">
                  <Typography token="font-medium/text/sm" color="info" colorWeight="900">
                    <div className="underline cursor-pointer" onClick={handleClickLearnMore} onKeyDown={handleClickLearnMore} role="button" tabIndex={0}>
                      Learn more
                    </div>
                  </Typography>
                  <Typography token="font-medium/text/sm" color="info" colorWeight="900">
                    <div className="underline cursor-pointer" onClick={handleDismissAudioNewsletterBanner} onKeyDown={handleDismissAudioNewsletterBanner} role="button" tabIndex={0}>
                      Dismiss
                    </div>
                  </Typography>
                </div>
              </div>
            }
            isScreenWide={false}
          />
        }
        <div>
          <PublishTo
            emailPostSendTargets={emailPostSendTargets}
            formData={formData}
            loadingEmailPostTargets={loadingEmailPostTargets}
            loadingWebPostTargets={loadingWebPostTargets}
            openEmailSection={openEmailSection}
            openPublishToSection={openPublishToSection}
            openWebSection={openWebSection}
            publishType={publishType}
            refetchEmailPostTargets={refetchEmailPostTargets}
            refetchWebPostTargets={refetchWebPostTargets}
            setOpenEmailSection={setOpenEmailSection}
            setOpenPublishToSection={setOpenPublishToSection}
            setOpenWebSection={setOpenWebSection}
            webPostSendTargets={webPostSendTargets}
          />
        </div>
        <EmailAudience
          display
          emailPostSendTargets={emailPostSendTargets}
          formData={formData}
          openEmailSection={openEmailSection}
          paidTiers={paidTiers}
          refetchEmailPostTargets={refetchEmailPostTargets}
          setOpenEmailSection={setOpenEmailSection}
          setOpenWebSection={setOpenWebSection}
          setOpenPublishToSection={setOpenPublishToSection}
          setPostEditorStep={setPostEditorStep}
          showWebSection={showWebSection}
        />
        <WebAudience
          display
          formData={formData}
          onChange={onChange}
          openWebSection={openWebSection}
          paidTiers={paidTiers}
          refetchWebPostTargets={refetchWebPostTargets}
          setOpenEmailSection={setOpenEmailSection}
          setOpenPublishToSection={setOpenPublishToSection}
          setOpenWebSection={setOpenWebSection}
          setPostEditorStep={setPostEditorStep}
          showEmailSection={showEmailSection}
          tierOptions={tierOptions}
          webPostSendTargets={webPostSendTargets}
        />
      </div>
      <div className="md:w-1/3">
        <SummaryCard
          publishType={publishType}
          emailPostSendTargets={emailPostSendTargets}
          webPostSendTargets={webPostSendTargets}
          showEmailSection={showEmailSection}
          showWebSection={showWebSection}
        />
      </div>
    </div>
  );
}

export default Audience;
