import { useEffect, useRef, useState } from 'react';

import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { Button } from '@/components/TiptapEditor/components/ui/Button';
import Checkbox from '@/components/TiptapEditor/components/ui/Checkbox';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { InputField } from '@/components/TiptapEditor/components/ui/Input';
import { Panel, PanelDivider, PanelHeader, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ContentTag } from '@/interfaces/content_tag';
import { Post } from '@/interfaces/post';
import { normalizeString } from '@/utils';

interface Props {
  onBack?: () => void;
  post: Post;
  updatePost: (data: Post) => void;
  contentTags: ContentTag[];
  setShowCreateContentTagModal: (value: boolean) => void;
  refetchContentTags: () => void;
}

const AddContentTagMenu = ({
  onBack,
  post,
  contentTags,
  updatePost,
  refetchContentTags,
  setShowCreateContentTagModal,
}: Props) => {
  const initialSearchResults = contentTags.map((filteredTag) => ({
    label: filteredTag.display,
    value: filteredTag.id,
  }));
  const prevContentTagsRef = useRef<string>();
  const [searchResults, setSearchResults] = useState<{ label: string; value: string }[]>();
  const [selectedContentTags, setSelectedContentTags] = useState<string[]>(post.content_tag_ids);

  useEffect(() => {
    const saveTags = async () => {
      await updatePost({ ...post, content_tag_ids: selectedContentTags });
      refetchContentTags();
    };

    if (prevContentTagsRef.current !== JSON.stringify(selectedContentTags)) {
      saveTags();
    }

    prevContentTagsRef.current = JSON.stringify(selectedContentTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContentTags]);

  const onContentTagSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e?.target?.value;
    const results =
      contentTags
        ?.filter((filteredTag) => normalizeString(filteredTag.display).includes(query))
        .map((filteredTag) => ({ label: filteredTag.display, value: filteredTag.id })) || [];

    setSearchResults(results);
  };

  const onContentTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const contentTagId = e.target.name;

    if (!checked) {
      setSelectedContentTags((prev) => prev.filter((contentTag) => contentTag !== contentTagId));
    } else {
      setSelectedContentTags((prev) => [...prev, contentTagId]);
    }
  };

  const tagList = searchResults || initialSearchResults;

  return (
    <Styled.ContentWrapper>
      <Panel>
        {onBack && (
          <PanelHeader>
            <Button
              $variant="quaternary"
              $size="small"
              $leftSlot={<Icon name="ChevronLeft" />}
              onClick={onBack}
              $fullWidth
            >
              Add content tags
            </Button>
          </PanelHeader>
        )}
        <PanelSection>
          <Styled.PanelSectionInner>
            <InputField onChange={onContentTagSearch} placeholder="Search and select" />
          </Styled.PanelSectionInner>
          <Styled.PanelSectionInner>
            <div className="mt-2">
              {tagList.length > 0 && (
                <ul>
                  {tagList.map((contentTag) => {
                    return (
                      <li
                        key={contentTag.value}
                        className="flex cursor-pointer hover:bg-white hover:bg-opacity-10 rounded-md mt-[.1rem] px-[0.3125rem] py-[0.375rem]"
                      >
                        <Checkbox
                          id={`content-tag-${contentTag.value}-label`}
                          name={contentTag.value}
                          onChange={onContentTagChange}
                          checked={selectedContentTags.includes(contentTag.value)}
                        />
                        <label
                          htmlFor={`content-tag-${contentTag.value}-label`}
                          className="ml-2 text-sm text-white cursor-pointer"
                        >
                          {contentTag.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              )}
              {!tagList ||
                (tagList?.length === 0 && (
                  <ul>
                    <li className="flex rounded-md mt-[.1rem] px-[0.3125rem] py-[0.375rem]">No content tags found.</li>
                  </ul>
                ))}
            </div>
          </Styled.PanelSectionInner>
          <PanelDivider />
          <Button
            $variant="quaternary"
            $size="small"
            onClick={() => {
              setShowCreateContentTagModal(true);
            }}
            $fullWidth
            $rightSlot={
              <span className="ml-auto mr-0">
                <Icon name="Plus" />
              </span>
            }
          >
            <span className="w-full">Create content tag</span>
          </Button>
          <Button
            $variant="quaternary"
            $size="small"
            onClick={() => {
              window.location.href = `${window.location.origin}/content_tags`;
            }}
            $fullWidth
            $rightSlot={
              <span className="ml-auto mr-0">
                <Icon name="External" />
              </span>
            }
          >
            <span className="w-full">Manage content tags</span>
          </Button>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default AddContentTagMenu;
