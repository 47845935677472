import React, { createContext, useMemo } from 'react';
import { useQuery } from 'react-query';

import { Settings } from '@/interfaces/setting';
import api from '@/services/swarm';

import { useCurrentPublicationState } from './current-publication-context';

interface ISettingsContext {
  settings?: Settings;
  isLoading: boolean;
  isError: boolean;
  reloadSettings: () => void;
}

const SettingsContext = createContext<ISettingsContext | undefined>(undefined);

SettingsContext.displayName = 'SettingsContext';

const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const [publicationId] = useCurrentPublicationState();

  const settingsQuery = useQuery<Settings>(
    ['publications', publicationId, 'settings'],
    () =>
      api
        .get('/settings', {
          params: {
            publication_id: publicationId,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60, // 1 minute
    }
  );

  const { data: settings, isLoading, isError } = settingsQuery;


  const contextProviderProps = useMemo(() => {
    const reloadSettings = () => settingsQuery.refetch();

    return {reloadSettings, settings, isLoading, isError}
  }, [isError, isLoading, settings, settingsQuery]);

  return (
    <SettingsContext.Provider value={contextProviderProps}>
      {children}
    </SettingsContext.Provider>
  );
};

function useSettings() {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(`useSettings must be used within a SettingsProvider`);
  }
  return context;
}

export { SettingsContext, SettingsProvider, useSettings };
