import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AccordionCard } from "@/components/Accordion/variants/AccordionCard";
import { PostEditorSteps } from "@/components/Layout/PostEditorLayout/constants";
import Multiselect from "@/components/Multiselect";
import { Typography } from "@/components/Typography";
import { useSegments } from "@/hooks";
import { useCreatePostTarget, useDeletePostTarget } from "@/hooks/usePostTargets";
import { Option } from "@/interfaces/general";
import { Post, PostSendStatus } from "@/interfaces/post";
import { PostTarget, PostTargetAction, PostTargetPlatform } from "@/interfaces/post_target";
import { Segment } from "@/interfaces/segment";
import { Tier } from "@/interfaces/tier";
import { Button } from "@/ui/Button";

interface Props {
  display: boolean;
  emailPostSendTargets: any[];
  formData: Post;
  openEmailSection: boolean;
  paidTiers: Tier[];
  refetchEmailPostTargets: () => void;
  setOpenEmailSection: (open: boolean) => void;
  setOpenPublishToSection: (open: boolean) => void;
  setOpenWebSection: (open: boolean) => void;
  setPostEditorStep?: (step: PostEditorSteps) => void;
  showWebSection: boolean;
}

const EmailAudience = ({
  display,
  emailPostSendTargets,
  formData,
  openEmailSection,
  paidTiers,
  refetchEmailPostTargets,
  setOpenEmailSection,
  setOpenPublishToSection,
  setOpenWebSection,
  setPostEditorStep,
  showWebSection,
}: Props) => {
  const navigate = useNavigate();
  const [emailDefaultAudiencesQuery, setEmailDefaultAudiencesQuery] = useState("");
  const [emailIncludeSegmentsQuery, setEmailIncludeSegmentsQuery] = useState("");
  const [emailExcludeSegmentsQuery, setEmailExcludeSegmentsQuery] = useState("");
  const [includedOptionsSegments, setIncludedOptionsSegments] = useState<Option[]>([]);
  const [excludedOptionsSegments, setExcludedOptionsSegments] = useState<Option[]>([]);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [excludedSegmentPostTargets, setExcludedSegmentPostTargets] = useState<PostTarget[]>([]);
  const [includedSegmentPostTargets, setIncludedSegmentPostTargets] = useState<PostTarget[]>([]);
  const [audienceSegmentPostTargets, setAudienceSegmentPostTargets] = useState<PostTarget[]>([]);

  const createEmailPostTarget = useCreatePostTarget(formData?.id || "", PostTargetPlatform.EMAIL);
  const deletePostTarget = useDeletePostTarget(formData?.id || "");

  const { data: segmentsData } = useSegments({
    search: "",
    shouldFetchAll: true,
  });
  useEffect(() => {
    if (segmentsData) {
      setSegments(segmentsData.pages.flatMap((page) => page.segments))
    }
  }, [segmentsData]);

  useEffect(() => {
    setExcludedSegmentPostTargets(emailPostSendTargets.filter(
      (target) => target.receiver_type === "Segment" && target.action === PostTargetAction.EXCLUDE
    ))
    setIncludedSegmentPostTargets(emailPostSendTargets.filter(
      (target) => target.receiver_type === "Segment" && target.action === PostTargetAction.INCLUDE
    ))
    setAudienceSegmentPostTargets(emailPostSendTargets.filter(
      (target) => target.receiver_type === "Publication"
    ))
  }, [emailPostSendTargets]);

  useEffect(() => {
    const optionSegments = segments.filter(
      (segment) => includedSegmentPostTargets?.every((target) => target.receiver_id !== segment.id) && segment.name.toLowerCase().includes(emailExcludeSegmentsQuery.toLowerCase())
    ).flatMap(
      (segment) => (
        {
          label: segment.name,
          value: segment.id,
          subtitle: `${segment.num_members} subscribers`,

        }
      )
    );
    setExcludedOptionsSegments(optionSegments);
  }, [segments, emailExcludeSegmentsQuery, includedSegmentPostTargets]);

  useEffect(() => {
    const optionSegments = segments.filter(
      (segment) => excludedSegmentPostTargets?.every((target) => target.receiver_id !== segment.id) && segment.name.toLowerCase().includes(emailIncludeSegmentsQuery.toLowerCase())
    ).flatMap(
      (segment) => (
        {
          label: segment.name,
          value: segment.id,
          subtitle: `${segment.num_members} subscribers`,

        }
      )
    );
    setIncludedOptionsSegments(optionSegments);
  }, [segments, emailIncludeSegmentsQuery, excludedSegmentPostTargets]);


  const handleDeselectEmailSegment = (name: string, deselected: string) => {
    const postTarget =  emailPostSendTargets.find((target) => target.receiver_id === deselected);
    if (postTarget) {
      deletePostTarget.mutate(postTarget.id, {
        onSuccess: () => refetchEmailPostTargets(),
      });
    }
  }

  const handleDeselectEmailAudience = (name: string, deselected: string) => {
    const postTarget = emailPostSendTargets.find((target) => target.tier_id === deselected && target.receiver_type === "Publication");
    if (postTarget) {
      deletePostTarget.mutate(postTarget.id, {
        onSuccess: () => refetchEmailPostTargets(),
      });
    }
  }

  const handleEmailAudienceFooterButtonClick = () => {
    setOpenEmailSection(false);
    if (showWebSection) {
      setOpenWebSection(true);
    } else if (setPostEditorStep) {
        setPostEditorStep(PostEditorSteps.EMAIL);
      }
  }

  const postSent = formData.send_status === PostSendStatus.SENT;

  const options = [
    {
      label: "All Free Subscribers",
      value: "free",
      subtitle: `${emailPostSendTargets.find((target) => target.tier === "free" && target.tier_id === "free")?.num_active_subscribers || 0} subscribers`,
    },
    {
      label: "All Premium subscribers",
      value: "premium",
      subtitle: `${paidTiers.reduce((acc, val) => acc + (val.active_subscriptions_count || 0), 0)} subscribers`,
      subItems: [
        ...paidTiers.map((tier) => {
          return {
            label: `All ${tier.name}`,
            value: tier.id,
            subtitle: `${tier.active_subscriptions_count || 0} subscribers`,
          }
        }),
      ],
    },
  ].filter(
    (option) => (
      option.label.toLowerCase().includes(
        emailDefaultAudiencesQuery.toLowerCase()
      ) ||
      option.subItems?.some(
        (subItem) => subItem.label.toLowerCase().includes(
          emailDefaultAudiencesQuery.toLowerCase()
        )
      )
    )
  );

  return (
    <div className={display ? '' : 'hidden'}>
      <AccordionCard
        title="Email Audience"
        titleSize="base"
        titleWeight="medium"
        subText=""
        topBorder={false}
        isControlledState
        isControlledStateOpen={openEmailSection}
        onClick={() => {
          if (!openEmailSection) {
            setOpenEmailSection(true);
            setOpenWebSection(false);
            setOpenPublishToSection(false);
          } else {
            setOpenEmailSection(false);
            setOpenPublishToSection(true);
          }
        }}
        footer={
          <div className="flex px-6 py-3 justify-end items-center gap-3 self-stretch bg-surface-50 rounded-b-lg">
            <div className="flex flex-row gap-3">
              <Button onClick={() => {
                  setOpenPublishToSection(true);
                  setOpenEmailSection(false);
                }} variant="flush">Back</Button>
              <Button onClick={handleEmailAudienceFooterButtonClick} type="button" variant="primary" size="sm">{showWebSection ? 'Next' : 'Continue'}</Button>
            </div>
          </div>
        }
        marginTop="mt-0"
      >
        <div className="flex flex-col gap-6">
          <Multiselect
            emptyLabel="Search and select"
            shouldCloseOnSelection={false}
            labelText="Default audiences"
            name="email-default-audiences"
            showClearAll={false}
            onDeselect={handleDeselectEmailAudience}
            onSearchQueryChange={(query) => setEmailDefaultAudiencesQuery(query)}
            disabled={postSent}
            onSearch={() => {
              return new Promise((resolve) => {
                resolve(options);
              });
            }}
            onDeselectAll={() => {}}
            onSelect={(name, value) => {
              createEmailPostTarget.mutate({
                params: {
                  action: PostTargetAction.INCLUDE,
                  receiver_type: "Publication",
                  receiver_id: formData?.publication_id || "",
                  tier_id: value,
                }
              }, {
                onSuccess: () => refetchEmailPostTargets(),
              });
            }}
            values={
              audienceSegmentPostTargets.map(
                (target) => (
                  {
                    value: target.tier_id,
                    label: target.receiver_display_name,
                    subtitle: `${target.num_active_subscribers} subscribers`,
                  }
                )
              ) || []
            }
          />
          <Multiselect
            emptyLabel="Search and select"
            shouldCloseOnSelection={false}
            labelText="Included segments"
            placeholderText="Search and select"
            name="email-included-segments"
            showClearAll={false}
            disabled={postSent}
            onDeselect={handleDeselectEmailSegment}
            onSearchQueryChange={(query) => setEmailIncludeSegmentsQuery(query)}
            onSearch={() => {
              return new Promise((resolve) => {
                resolve(includedOptionsSegments);
              });
            }}
            onDeselectAll={() => {}}
            onSelect={(name, value) => {
              createEmailPostTarget.mutate({
                params: {
                  action: PostTargetAction.INCLUDE,
                  receiver_type: "Segment",
                  receiver_id: value,
                }
              }, {
                onSuccess: () => refetchEmailPostTargets(),
              });
            }}
            values={
              includedSegmentPostTargets.map(
                (target) => {
                  return {
                    value: target.receiver_id,
                    label: target.receiver_display_name,
                    subtitle: `${target.num_active_subscribers} subscribers`,
                  }
                }
              ) || []
            }
          />
          <Multiselect
            emptyLabel="Search and select"
            shouldCloseOnSelection={false}
            labelText="Excluded segments"
            placeholderText="Search and select"
            name="email-excluded-segments"
            showClearAll={false}
            disabled={postSent}
            onDeselect={handleDeselectEmailSegment}
            onSearchQueryChange={(query) => setEmailExcludeSegmentsQuery(query)}
            onSearch={() => {
              return new Promise((resolve) => {
                resolve(excludedOptionsSegments);
              });
            }}
            onDeselectAll={() => {}}
            onSelect={(name, value) => {
              createEmailPostTarget.mutate({
                params: {
                  action: PostTargetAction.EXCLUDE,
                  receiver_type: "Segment",
                  receiver_id: value,
                }
              }, {
                onSuccess: () => refetchEmailPostTargets(),
              });
            }}
            values={
              excludedSegmentPostTargets.map(
                (target) => (
                  {
                    value: target.receiver_id,
                    label: target.receiver_display_name,
                    subtitle: `${target.num_active_subscribers} subscribers`,
                  }
                )
              ) || []
            }
          />
          <Button onClick={() => navigate("/segments")} type="button" variant="flush" size="sm" className="w-fit">
            <Typography token="font-medium/text/xs" color="secondary" colorWeight="600" className="underline cursor-pointer">
                Manage segments
            </Typography>
          </Button>
        </div>
      </AccordionCard>
    </div>
  )
}

export default EmailAudience;
