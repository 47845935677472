import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { usePages } from '@/hooks/usePages';
import { useSite } from '@/hooks/useSite';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';
import { Page } from '@/interfaces/dream_builder/page';
import { PageRoute } from '@/interfaces/dream_builder/page_route';
import { Site } from '@/interfaces/dream_builder/site';


interface IWebsiteContext {
  pagesRoutes: PageRoute | undefined;
  defaultRoutes: PageRoute | undefined;
  updatePagesRoutes: (pageRoute: PageRoute) => Promise<void>;
  pages: Page[] | undefined;
  site: Site | undefined;
  isLoading: boolean;
  isError: boolean;
}

const WebsiteContext = createContext<IWebsiteContext | undefined>(undefined);

WebsiteContext.displayName = 'WebsiteContext';

const WebsiteProvider = ({ children }: { children: React.ReactNode }) => {
  const pagesQuery = usePages();
  const { data: site, isLoading: isSiteLoading, isError: isSiteError } = useSite();
  const draftSiteVersion = site?.draft_site_version
  const { data: pages, isLoading: isPagesLoading, isError: isPagesError } = pagesQuery;
  const siteVersionMutation = useUpdateSiteVersion({
    id: draftSiteVersion?.id || '',
  });

  const isLoading = isPagesLoading || isSiteLoading;
  const isError = isPagesError || isSiteError;

  const [pagesRoutes, setPagesRoutes] = useState<PageRoute | undefined>(draftSiteVersion?.routes);
  const [defaultRoutes, setDefaultRoutes] = useState<PageRoute | undefined>(draftSiteVersion?.default_routes);

  useEffect(() => {
    if (draftSiteVersion) {
      setPagesRoutes(draftSiteVersion.routes);
      setDefaultRoutes(draftSiteVersion.default_routes);
    }
  }, [draftSiteVersion]);

  const updatePagesRoutes = useCallback(
    async (pageRoute: PageRoute) => {
      const originalPageRoute = { ...pagesRoutes } as PageRoute;
      // optimistic update
      setPagesRoutes(pageRoute);
      try {
        await siteVersionMutation.mutateAsync({
          routes: pageRoute,
        });
      } catch (e) {
        // if fail, revert to original
        setPagesRoutes(originalPageRoute);
        toast.error('Failed to update page routes');
      }
    },
    [pagesRoutes, siteVersionMutation]
  );

  const ctxValue = useMemo(
    () => ({
      pagesRoutes,
      defaultRoutes,
      updatePagesRoutes,
      pages: pages?.pages,
      site,
      isLoading,
      isError,
    }),
    [pagesRoutes, defaultRoutes, updatePagesRoutes, pages, isLoading, isError, site]
  );

  return <WebsiteContext.Provider value={ctxValue}>{children}</WebsiteContext.Provider>;
};

type PageRouteWithPath = {
  path: string[];
  route: PageRoute;
};

class PageRouteGetter {
  pages: Page[];

  defaultRoutes: PageRoute; // default routes that aren't editable

  routes: PageRoute; // editable routes

  allRoutes: PageRoute; // complete page route of both routes & default routes

  pageIDToPageMap: { [id: string]: Page } = {};

  pageIDToRouteMap: { [id: string]: PageRouteWithPath } = {};

  pagePathToPageMap: { [path: string]: Page } = {};

  constructor(pages: Page[], routes: PageRoute, defaultRoutes: PageRoute) {
    this.pages = pages;
    this.allRoutes = {
      page_id: routes.page_id,
      page_version_id: routes.page_version_id,
      children: {
        ...routes?.children,
        ...defaultRoutes?.children,
      },
      children_keys: [
        ...(routes?.children_keys || []),
        ...(defaultRoutes?.children_keys || []),
      ],
    };
    this.routes = routes;
    this.defaultRoutes = defaultRoutes;
    this.buildPageIDToPageMap();
    this.buildPageIDToRouteMap();
    this.buildPagePathToPageMap(this.pageIDToPageMap);
  }

  private buildPageIDToPageMap() {
    this.pageIDToPageMap = this.pages?.reduce((acc, page) => {
      return {
        ...acc,
        [page.id]: page,
      };
    }, {});
  }

  private buildPageIDToRouteMap() {
    function buildRouteMap(currMap: {}, allRoutes: PageRoute, slug: string | undefined, parentPath: string[] = []) {
      const { page_id: pageID } = allRoutes;
      const path = slug ? [...parentPath, slug] : parentPath;

      let map: { [id: string]: PageRouteWithPath } = {
        ...currMap,
        [pageID]: {
          path,
          route: allRoutes,
        },
      };

      if (allRoutes.children) {
        const childrenMap = Object.entries(allRoutes.children).reduce((acc, [key, value]) => {
          return {
            ...acc,
            ...buildRouteMap({}, value, key, path),
          };
        }, {});

        map = {
          ...map,
          ...childrenMap,
        };
      }
      return map;
    }

    this.pageIDToRouteMap = buildRouteMap({}, this.allRoutes, undefined);
  }

  private buildPagePathToPageMap(pageIDToPageMap: { [id: string]: Page }) {
    function buildPageMap(currMap: {}, allRoutes: PageRoute, slug: string | undefined, parentPath: string[]) {
      const path = slug ? [...parentPath, slug] : parentPath;

      let map: { [id: string]: Page } = {
        ...currMap,
        [path.join('/')]: pageIDToPageMap[allRoutes.page_id],
      };

      if (allRoutes.children) {
        const childrenMap = Object.entries(allRoutes.children).reduce((acc, [key, value]) => {
          return {
            ...acc,
            ...buildPageMap({}, value, key, path),
          };
        }, {});

        map = {
          ...map,
          ...childrenMap,
        };
      }

      return map;
    }

    this.pagePathToPageMap = buildPageMap({}, this.allRoutes, undefined, []);
  }

  getPageFromID(id: string): Page | undefined {
    return this.pageIDToPageMap[id];
  }

  getPageRouteFromID(id: string): PageRouteWithPath | undefined {
    return this.pageIDToRouteMap[id];
  }

  getPageFromPath(path: string): Page | undefined {
    return this.pagePathToPageMap[path];
  }

  isDefaultPage(id: string): boolean {
    return this.defaultRoutes?.children && Object.values(this.defaultRoutes?.children)?.map(r => r.page_id)?.includes(id) || false;
  }

  getHomePageID(): string | undefined {
    return this.routes?.page_id;
  }

  getAllRoutes(): PageRoute {
    return this.allRoutes;
  }
}

/* useWebsiteSite returns the top level page route */
function useWebsiteContext() {
  const context = React.useContext(WebsiteContext);
  if (context === undefined) {
    throw new Error(`useWebsiteContext must be used within a WebsiteContext`);
  }
  return context;
}


/* useWebsitePageRouteGetter returns a map of page id to page */
function useWebsitePageRouteGetter() {
  const context = React.useContext(WebsiteContext);
  if (context === undefined) {
    throw new Error(`useWebsitePageIDToPageMap must be used within a WebsiteContext`);
  }
  const [pageRouteGetter, setPageRouteGetter] = useState<PageRouteGetter>();

  useEffect(() => {
    if (context?.pages && context?.pagesRoutes && context?.defaultRoutes) {
      setPageRouteGetter(
        new PageRouteGetter(context.pages, context.pagesRoutes, context.defaultRoutes)
      );
    }
  }, [context]);

  return pageRouteGetter;
}

function useCurrentPage() {
  const context = React.useContext(WebsiteContext);
  if (context === undefined) {
    throw new Error(`useWebsitePageIDToPageMap must be used within a WebsiteContext`);
  }
  const { pageId } = useParams() as unknown as { pageId: string };
  const pageRouteGetter = useWebsitePageRouteGetter();
  const page = pageRouteGetter?.getPageFromID(pageId);
  const pageRoute = pageRouteGetter?.getPageRouteFromID(pageId);

  return {
    page,
    pageRoute,
  };
}
export {
  useCurrentPage,
  useWebsiteContext,
  useWebsitePageRouteGetter,
  WebsiteContext,
  WebsiteProvider,
};
