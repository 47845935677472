import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

const analytics = AnalyticsBrowser.load(
  {
    writeKey: window?.env?.REACT_APP_CIO_CDP_KEY as string
  },
  {
    "integrations": {
      "Customer.io In-App Plugin": {
        siteId: window?.env?.REACT_APP_CIO_IN_APP_SITE_ID as string
      }
    }
  }
);

export default analytics;
