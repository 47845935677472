import { useState } from "react";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";

import Banner from "@/components/Banner";
import SupportTicketModal from "@/components/SupportTicketModal";
import useBoostsFrozen from "@/hooks/boosts/useBoostsFrozen";

const BoostsFrozenBanner = () => {
  const boostsFrozen = useBoostsFrozen()?.data?.boosts_frozen || false;
  const [open, setOpen] = useState(false);

  if(!boostsFrozen) return null;
  return(
    <>
      <SupportTicketModal open={open} onClose={() => setOpen(false)} onSubmit={() => setOpen(false)} />
      <Banner
        isScreenWide={false}
        variant="danger"
        title="Your access to the Boosts platform has been disabled. Please contact customer support by submitting a support ticket for assistance."
        ctaText="Contact Support"
        ctaIcon={<ChatBubbleLeftRightIcon className="h-5 w-5" />}
        onClick={() => setOpen(true)}
      />
    </>
  )
};

export default BoostsFrozenBanner;
