import { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import Tippy from '@tippyjs/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Post } from '@/interfaces/post';

import ThumbnailActionsMenu from '../ThumbnailActionsMenu';
import ThumbnailTooltipMenu from '../ThumbnailTooltipMenu';

interface Props {
  post: Post;
  updatePost: (data: Post) => void;
  thumbnailFileRef: React.RefObject<HTMLInputElement>;
  setShowImageLibrary: (value: boolean) => void;
}

const Thumbnail = ({ post, updatePost, thumbnailFileRef, setShowImageLibrary }: Props) => {
  const [showThumbnailActionMenus, setShowThumbnailActionMenus] = useState(false);
  const [thumbnailTippyMenusShown, setThumbnailTippyMenusShown] = useState(false);

  const onThumbnailMouseEnter = () => {
    setShowThumbnailActionMenus(true);
  };

  const onThumbnailMouseLeave = () => {
    if (!thumbnailTippyMenusShown) {
      setShowThumbnailActionMenus(false);
    }
  };

  const onThumbnailTippyShow = () => {
    setThumbnailTippyMenusShown(true);
  };

  const onThumbnailTippyHide = () => {
    setThumbnailTippyMenusShown(false);
  };

  useEffect(() => {
    if (!post.thumbnail_id || post.thumbnail_id === 'delete' || post.thumbnail_id === '') {
      setShowThumbnailActionMenus(false);
    }
  }, [post.thumbnail_id]);

  return (
    <div className="relative" onMouseEnter={onThumbnailMouseEnter} onMouseLeave={onThumbnailMouseLeave}>
      <Transition
        show={showThumbnailActionMenus}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute -ml-10 z-50">
          <Tippy
            offset={[0, 8]}
            placement="bottom-start"
            popperOptions={{
              modifiers: [{ name: 'flip' }],
            }}
            onShow={onThumbnailTippyShow}
            onHide={onThumbnailTippyHide}
            trigger="click"
            interactive
            content={
              <div className="absolute z-50">
                <ThumbnailActionsMenu
                  updatePost={updatePost}
                  post={post}
                  fileRef={thumbnailFileRef}
                  setShowImageLibrary={setShowImageLibrary}
                  showFullMenu
                />
              </div>
            }
          >
            <div>
              <Button $leftSlot={<Icon name="DragMenu" />} $variant="tertiary" $size="small" $isIconButton />
            </div>
          </Tippy>
        </div>
      </Transition>
      <div className="relative">
        <Tippy
          offset={[0, 8]}
          placement="top"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          onShow={onThumbnailTippyShow}
          onHide={onThumbnailTippyHide}
          trigger="click"
          interactive
          content={
            <div className="absolute z-50 mt-[-3rem]">
              <ThumbnailTooltipMenu
                updatePost={updatePost}
                post={post}
                fileRef={thumbnailFileRef}
                setShowImageLibrary={setShowImageLibrary}
              />
            </div>
          }
        >
          <div>
            <img
              src={post.thumbnail?.url}
              alt={post.thumbnail?.alt}
              className="mb-[2rem] h-full w-auto max-h-[25rem]"
            />
          </div>
        </Tippy>
      </div>
    </div>
  );
};

export default Thumbnail;
