import { useQuery } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';
import analytics from '@/utils/analytics';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import { Organization } from '../../interfaces/organization';
import api from '../../services/swarm';

const useOrganization = (organizationId: string | undefined) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { currentUser } = useCurrentUser();

  return useQuery<Organization | any>(
    ['organizations', organizationId],
    () =>
      api
        .get(`/organizations/${organizationId}`, {
          params: {
            publication_id: currentPublicationId,
          },
        })
        .then((res) => {
          const profitwellPayload = {
            user_id: res.data.organization.stripe_customer_id,
          };

          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
          });

          window?.profitwell?.('start', profitwellPayload);

          return res.data;
        }),
    {
      staleTime: 10000,
      enabled: !!organizationId,
    }
  );
};

export default useOrganization;
