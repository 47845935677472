export function dateToDateString(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short'
  };

  const formatter = new Intl.DateTimeFormat(undefined, options);
  const formattedDate = formatter.format(date);

  // Convert the first letter of the month to uppercase
  return formattedDate.replace(/^\w/, (c) => c.toUpperCase());
}
